import { FaChevronRight, FaPlus } from "react-icons/fa6";
import React, { useEffect, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { MdOutlineEngineering, MdOutlineLocalPhone } from "react-icons/md";
import profs from "./profs.json";
import { LuMapPin } from "react-icons/lu";

const CreateApplicant = () => {
  //modal
  const [modal, setModal] = useState(true);
  const [page, setPage] = useState(1);

  // page 1
  const [images, setImages] = useState([]);
  const [camp, setCamp] = useState("");

  // page 2
  const [checked, setChecked] = useState([]);
  const [other, setOther] = useState("");

  //modal
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  //profs
  const [major, setMajor] = useState("engineer");

  const vehicle = [
    "Экскаватор/Excavator",
    "Ковш/Loader",
    "Хово/Dump truck",
    "Дамп/Mining dump truck",
    "Гинжит буллдозер",
    "Кран",
    "Шоул Экскаватор / Shovel excavator",
    "Буллдозер/Bullldozer",
    "Motor graders",
    "Blasthole drills",
    "Hardrock drill",
    "Hardrock bolter",
  ];

  const equip = [
    "Насос / Pump",
    "Баяжуулах / Flotation",
    "Өтгөрүүлэгч / Thickener",
    "Нунтаглалт / Grinding",
    "Туузан дамжуурга / Conveyor",
    "Туузан дамжуурга / Conveyor",
  ];

  const engineer = [
    "Геологич",
    "Геотехникийн инженер",
    "Металлургийн инженер",
    "Механик инженер",
    "Цахилгааны инженер",
    "Барилгын инженер",
    "Процесс удирдлагын инженер",
    "Химийн инженер",
    "Зургийн инженер",
    "Геодези/Маркшейдер",
    "Автоматжуулалтын инженер",
    "Агааржуулалтын инженер",
    "Материалын инженер",
    "Теслийн инженер",
    "Системийн инженер",
    "Аюулгүй байдлын инженер",
    "IT инженер",
    "Тоног төхөөрөмжийн инженер",
    "Усны инженер",
    "Чанарын инженер",
  ];

  const welding = [
    "Гар гагнуур",
    "Хагас автомат",
    "Автомат гагнуур",
    "Цахилгаан нуман гагнуур",
    "Даралтын гагнуур",
    "Хий гагнуур (Gas Welding)",
    "Хэт авианы гагнуур (Ultrasonic Welding)",
    "Лазер гагнуур",
    "Хуванцар гагнуур (Plastic Welding)",
  ];

  const mechanic = [
    "Хүнд овор",
    "Дунд овор",
    "Хөнгөн овор",
    "Суурин тоног төхөөрөмж",
    "Өргөх тоног төхөөрөмж",
  ];

  const habea = [
    "ХАБЭА зөвлөх",
    "ХАБЭА ажилтан",
    "Байгаль орчны мэргэжилтэн",
    "Байгаль орчны эрдэмтэн",
    "Байгаль орчны менежер",
    "Тогтвортой хөгжлийн менежер",
    "Хөдөлмөрийн эрүүл мэндийн зөвлөх",
    "Онцгой байдлын зохицуулагч",
    "Эрсдэлийн менежментийн мэргэжилтэн",
    "Байгаль орчны зохицуулагч",
    "Агаарын чанарын мэргэжилтэн",
    "Хог хаягдлын менежер",
    "Усны нөөцийн менежер",
    "Биологийн олон янз байдлын зөвлөх",
    "Био олон янз байдлын зөвлөх",
  ];

  //
  const [data, setData] = useState({
    name: "",
    exp: "",
    prof: "",
    workHour: "",
    special: "",
    equip: [],
    car: {
      tech: [],
      license: "",
    },
    condition: "",
    rouster: "",
    salary: "",
    duty: "",
    requirement: "",
    information: "",
    location: "",
    phone: "",
  });

  const check = [
    "👷 Каск",
    "Куртик",
    "🦺 Хамгаалалтын хантааз",
    "Бээлий",
    "🥾 Ажлын гутал",
    "Өмд",
  ];

  const license = [
    "A",
    "B",
    "BE",
    "C1",
    "C1E",
    "C",
    "CE",
    "D1",
    "D1E",
    "D",
    "DE",
    "M",
  ];

  useEffect(() => {
    switch (data.prof) {
      case "Машин механизмын оператор":
        setMajor("car");
        break;

      case "Инженер":
        setMajor("engineer");
        break;
      case "Гагнуур":
        setMajor("welding");
        break;
      case "Механик":
        setMajor("mechanic");
        break;
      case "Аюулгүй ажиллагаа":
        setMajor("habea");
        break;
      default:
        setMajor("equip");
    }
  }, [data.prof]);

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    const max = 25 * 1024 * 1024;

    const validFiles = files.filter((file) => {
      if (file.size <= max) {
        return true;
      } else {
        alert(`image is larger than 25 MB and will not be uploaded.`);
        return false;
      }
    });

    const newImages = validFiles.map((file) => URL.createObjectURL(file));
    setImages((prevImages) => [...prevImages, ...newImages]);
  };

  const handleImageDelete = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleCheckboxToggle = (item) => {
    setChecked((prevChecked) =>
      prevChecked.includes(item)
        ? prevChecked.filter((i) => i !== item)
        : [...prevChecked, item]
    );
  };

  const handleSelect = (item) => {
    if (major === "car") {
      setData((prevData) => ({
        ...prevData,
        car: {
          ...prevData.car,
          tech: prevData.car.tech.includes(item)
            ? prevData.car.tech.filter((i) => i !== item)
            : [...prevData.car.tech, item],
        },
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        equip: data.equip.includes(item)
          ? data.equip.filter((i) => i !== item)
          : [...data.equip, item],
      }));
    }
  };

  const items =
    major === "car"
      ? vehicle
      : major === "equip"
      ? equip
      : major === "engineer"
      ? engineer
      : major === "welding"
      ? welding
      : major === "mechanic"
      ? mechanic
      : habea;

  const selectedItems = major === "car" ? data.car.tech : data.equip;

  return (
    <div className="pt-11 w-10/12 m-auto relative">
      <div>
        <div className="flex items-center justify-between mb-8">
          <p className="text-[#1E293B] font-semibold">
            Доорх талбарт ажлын зараа үүсгэнэ үү.
          </p>
          <button
            onClick={() => {
              setModal(true);
            }}
            className="text-[#fff] text-sm flex items-center gap-2 bg-[#324d72] rounded-xl py-2 px-3"
          >
            <MdOutlineEngineering className="text-base" />
            Хангамж, кемп мэдээлэл
          </button>
        </div>
        <form id="myForm">
          <div className="flex flex-wrap justify-center gap-10 items-center mb-10">
            <div>
              <p className="text-[#1E293B] text-sm mb-2">
                Ажлын байрны нэр{" "}
                {data.name === "" && <span className="text-[#FF5F6E]">*</span>}
              </p>
              <input
                className="w-[349px] py-3 border rounded-xl border-[#CECFD3] hover:bg-[#F4F6FB]"
                placeholder="Энд бичнэ үү."
                value={data.name}
                onChange={(e) => {
                  setData({ ...data, name: e.target.value });
                }}
                type="text"
              />
            </div>
            <div>
              <p className="text-[#1E293B] text-sm mb-2">
                Шаардагдах туршлага
                {data.exp === "" && <span className="text-[#FF5F6E]">*</span>}
              </p>
              <select
                className="w-[349px] py-3 border rounded-xl border-[#CECFD3] hover:bg-[#F4F6FB]"
                value={data.exp}
                onChange={(e) => {
                  setData({ ...data, exp: e.target.value });
                }}
                type="text"
              >
                <option value="" disabled defaultChecked>
                  Сонгоно уу.
                </option>
                <option value="1-5">1-5 жил</option>
                <option value="5-8">5-8 жил</option>
                <option value="8-10">8-10 жил</option>
                <option value="10-15">10-15 жил</option>
                <option value="15+">15-с дээш жил</option>
              </select>
            </div>
            <div>
              <p className="text-[#1E293B] text-sm mb-2">
                Мэргэжил{" "}
                {data.prof === "" && <span className="text-[#FF5F6E]">*</span>}
              </p>
              <select
                className="w-[349px] py-3 border rounded-xl border-[#CECFD3] hover:bg-[#F4F6FB]"
                value={data.prof}
                onChange={(e) => {
                  setData({ ...data, prof: e.target.value });
                }}
                type="text"
              >
                <option value="" disabled defaultChecked>
                  Сонгоно уу.
                </option>

                {profs.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <p className="text-[#1E293B] text-sm mb-2">
                Ажиллах цаг
                {data.workHour === "" && (
                  <span className="text-[#FF5F6E]">*</span>
                )}
              </p>
              <select
                className="w-[349px] py-3 border rounded-xl border-[#CECFD3] hover:bg-[#F4F6FB]"
                value={data.workHour}
                onChange={(e) => {
                  setData({ ...data, workHour: e.target.value });
                }}
                type="text"
              >
                <option value="" disabled defaultChecked>
                  Сонгоно уу.
                </option>

                <option value="fulltime">Бүтэн цагийн</option>
                <option value="parttime">Хагас цагийн</option>
                <option value="adviser">Зөвлөх үйлчилгээ</option>
              </select>
            </div>
            {data.prof === "" ||
            data.prof === "Инженер" ||
            data.prof === "Механик" ||
            data.prof === "Аюулгүй ажиллагаа" ||
            data.prof === "Гагнуур" ? (
              <div>
                <p className="text-[#1E293B] text-sm mb-2">
                  Нарийн мэргэжил
                  {data.special === "" && (
                    <span className="text-[#FF5F6E]">*</span>
                  )}
                </p>
                <select
                  className="w-[349px] py-3 border rounded-xl border-[#CECFD3] hover:bg-[#F4F6FB]"
                  value={data.special}
                  onChange={(e) => {
                    setData({ ...data, special: e.target.value });
                  }}
                  type="text"
                >
                  <option value="" disabled defaultChecked>
                    Сонгоно уу.
                  </option>

                  {items.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              ""
            )}
            {data.prof === "Машин механизмын оператор" ||
            data.prof === "Суурин төхөөрөмжийн оператор" ? (
              <div className="ms-5 ps-5 border-l ">
                <div className="mb-4">
                  <p className="text-[#1E293B] text-sm mb-2">
                    Тоног төхөөрөмж{" "}
                    {data.car.tech.length === 0 || data.equip.length === 0 ? (
                      <span className="text-[#FF5F6E]">*</span>
                    ) : (
                      ""
                    )}
                  </p>
                  <div className="relative">
                    <div
                      onClick={() => setIsOpen(!isOpen)}
                      className="w-[307px] py-3 border rounded-xl border-[#CECFD3] hover:bg-[#F4F6FB] cursor-pointer h-[50px] overflow-scroll bg-[#fff]"
                    >
                      {selectedItems.length > 0
                        ? selectedItems.join(", ")
                        : "Шаардагдах тоног төхөөрөмж сонгох"}
                    </div>
                    {isOpen && (
                      <div className="absolute z-10 w-full mt-2 bg-white border rounded-xl shadow-md max-h-60 overflow-y-auto">
                        {items.map((item, index) => (
                          <div
                            key={index}
                            className={`px-4 py-2 cursor-pointer hover:bg-[#F4F6FB] flex items-center`}
                            onClick={() => handleSelect(item)}
                          >
                            <input
                              type="checkbox"
                              checked={selectedItems.includes(item)}
                              readOnly
                              className="mr-1 rounded-md bg-[#EBEBEB] border border-[#EBEBEB]"
                            />
                            <span>{item}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                {major === "car" && (
                  <div>
                    <p className="text-[#1E293B] text-sm mb-2">
                      Жолооны ангилал
                      {data.car.license.length === 0 && (
                        <span className="text-[#FF5F6E]">*</span>
                      )}
                    </p>
                    <div className="relative">
                      <div
                        onClick={() => setIsOpen2(!isOpen2)}
                        className="w-[307px] py-3 border rounded-xl border-[#CECFD3] hover:bg-[#F4F6FB] cursor-pointer h-[50px] overflow-scroll bg-[#fff]"
                      >
                        {data.car.license.length > 0
                          ? data.car.license.join(", ")
                          : "Шаардагдах ангилал сонгох"}
                      </div>
                      {isOpen2 && (
                        <div className="absolute z-10 w-full mt-2 bg-white border rounded-xl shadow-md max-h-60 overflow-y-auto">
                          {license.map((item, index) => (
                            <div
                              key={index}
                              className={`px-4 py-2 cursor-pointer hover:bg-[#F4F6FB] flex items-center`}
                              onClick={() => {
                                setData((prevData) => ({
                                  ...prevData,
                                  car: {
                                    ...prevData.car,
                                    license: prevData.car.license.includes(item)
                                      ? prevData.car.license.filter(
                                          (i) => i !== item
                                        )
                                      : [...prevData.car.license, item],
                                  },
                                }));
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={data.car.license.includes(item)}
                                readOnly
                                className="mr-1 rounded-md bg-[#EBEBEB] border border-[#EBEBEB]"
                              />
                              <span>{item}</span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
            <div className="flex flex-col gap-10">
              <div>
                <p className="text-[#1E293B] text-sm mb-2">
                  Ажиллах нөхцөл
                  {data.car.license.length === 0 && (
                    <span className="text-[#FF5F6E]">*</span>
                  )}
                </p>
                <select
                  className="w-[349px] py-3 border rounded-xl border-[#CECFD3] hover:bg-[#F4F6FB]"
                  value={data.car.tech}
                  onChange={(e) => {
                    setData({ ...data, exp: e.target.value });
                  }}
                  type="text"
                >
                  <option value="" disabled defaultChecked>
                    Сонгоно уу.
                  </option>
                  <option value="office">Ирж ажиллах</option>
                  <option value="remote">Зайнаас ажиллах</option>
                  <option value="freelancer">Freelancer</option>
                </select>
              </div>
              <div>
                <p className="text-[#1E293B] text-sm mb-2">
                  Цалин
                  {data.salary === "" && (
                    <span className="text-[#FF5F6E]">*</span>
                  )}
                </p>
                <select
                  className="w-[349px] py-3 border rounded-xl border-[#CECFD3] hover:bg-[#F4F6FB]"
                  value={data.salary}
                  onChange={(e) => {
                    setData({ ...data, salary: e.target.value });
                  }}
                  type="text"
                >
                  <option value="" disabled defaultChecked>
                    Сонгоно уу.
                  </option>
                  <option value="1,5 сая-с доош">1,5 сая-с доош</option>
                  <option value="1,5 сая-3 сая">1,5 сая-3 сая</option>
                  <option value="3 сая-5 сая">3 сая-5 сая</option>
                  <option value="5 саяас дээш">5 саяас дээш</option>
                  <option value="тохиролцох">тохиролцох</option>
                </select>
              </div>
            </div>
            <div>
              <p className="text-[#1E293B] text-sm mb-2">
                Ростер
                {data.rouster === "" && (
                  <span className="text-[#FF5F6E]">*</span>
                )}
              </p>
              <select
                className="w-[349px] py-3 border rounded-xl border-[#CECFD3] hover:bg-[#F4F6FB]"
                value={data.rouster}
                onChange={(e) => {
                  setData({ ...data, rouster: e.target.value });
                }}
                type="text"
              >
                <option value="" disabled defaultChecked>
                  Сонгоно уу.
                </option>
                <option value="5/2">5/2</option>
                <option value="7/7">7/7</option>
                <option value="14/14">14/14</option>
                <option value="28/28">28/28</option>
                <option value="14/7">14/7</option>
                <option value="other">Бусад</option>
              </select>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center gap-10 mb-10">
            <div className="flex flex-col justify-center">
              <p className="text-[#1E293B] text-sm">
                Гүйцэтгэх үндсэн үүрэг{" "}
                {data.duty === "" && <span className="text-[#FF5F6E]">*</span>}
              </p>
              <textarea
                value={data.duty}
                onChange={(e) => {
                  setData({ ...data, duty: e.target.value });
                }}
                className="text-sm text-[#1E293B] p-3 h-[72px] w-[746px] rounded-xl border border-[#CECFD3]"
                placeholder="Энд дэлгэрэнгүй бичнэ үү."
              ></textarea>
            </div>
            <div className="flex flex-col justify-center">
              <p className="text-[#1E293B] text-sm">
                Ажлын байранд тавигдах шаардлага{" "}
                {data.requirement === "" && (
                  <span className="text-[#FF5F6E]">*</span>
                )}
              </p>
              <textarea
                value={data.requirement}
                onChange={(e) => {
                  setData({ ...data, requirement: e.target.value });
                }}
                className="text-sm text-[#1E293B] p-3 h-[72px] w-[746px] rounded-xl border border-[#CECFD3]"
                placeholder="Энд дэлгэрэнгүй бичнэ үү."
              ></textarea>
            </div>
            <div className="flex flex-col justify-center">
              <p className="text-[#1E293B] text-sm">Нэмэлт мэдээлэл</p>
              <textarea
                value={data.information}
                onChange={(e) => {
                  setData({ ...data, information: e.target.value });
                }}
                className="text-sm text-[#1E293B] p-3 h-[72px] w-[746px] rounded-xl border border-[#CECFD3]"
                placeholder="Орхигдуулсан зүйл үлдсэн бол нэмэлтээр энд бичнэ үү. "
              ></textarea>
            </div>
            <div className="flex justify-center gap-10 items-center">
              <div>
                <p className="text-[#1E293B] text-sm mb-2">
                  Байршил
                  {data.location === "" && (
                    <span className="text-[#FF5F6E]">*</span>
                  )}
                </p>
                <div className="relative flex items-center">
                  <div className="absolute left-3 flex items-center justify-center">
                    <LuMapPin className="text-lg" />
                  </div>
                  <select
                    className="w-[349px] ps-8 py-3 border rounded-xl border-[#CECFD3] hover:bg-[#F4F6FB]"
                    value={data.location}
                    onChange={(e) => {
                      setData({ ...data, location: e.target.value });
                    }}
                    type="text"
                  >
                    <option value="" disabled defaultChecked>
                      Сонгоно уу.
                    </option>
                    <option value="Архангай">Архангай</option>
                    <option value="Баян-Өлгий">Баян-Өлгий</option>
                    <option value="Баянхонгор">Баянхонгор</option>
                    <option value="Булган">Булган</option>
                    <option value="Говь-Алтай">Говь-Алтай</option>
                    <option value="Говьсүмбэр">Говьсүмбэр</option>
                    <option value="Дархан-Уул">Дархан-Уул</option>
                    <option value="Дорноговь">Дорноговь</option>
                    <option value="Дорнод">Дорнод</option>
                    <option value="Дундговь">Дундговь</option>
                    <option value="Завхан">Завхан</option>
                    <option value="Орхон">Орхон</option>
                    <option value="Өвөрхангай">Өвөрхангай</option>
                    <option value="Өмнөговь">Өмнөговь</option>
                    <option value="Сүхбаатар">Сүхбаатар</option>
                    <option value="Сэлэнгэ">Сэлэнгэ</option>
                    <option value="Төв">Төв</option>
                    <option value="Увс">Увс</option>
                    <option value="Ховд">Ховд</option>
                    <option value="Хөвсгөл">Хөвсгөл</option>
                    <option value="Хэнтий">Хэнтий</option>
                  </select>
                </div>
              </div>
              <div>
                <p className="text-[#1E293B] text-sm mb-2">
                  Холбогдох утасны дугаар
                  {data.phone === "" && (
                    <span className="text-[#FF5F6E]">*</span>
                  )}
                </p>
                <div className="relative flex items-center">
                  <div className="absolute left-3 flex items-center justify-center">
                    <MdOutlineLocalPhone className="text-lg" />
                  </div>
                  <input
                    className="w-[349px] ps-8 py-3 border rounded-xl border-[#CECFD3] hover:bg-[#F4F6FB]"
                    placeholder="Энд бичнэ үү."
                    value={data.phone}
                    onChange={(e) => {
                      setData({ ...data, phone: e.target.value });
                    }}
                    type="number"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <button
        className="fixed bottom-10 right-10 z-20 border border-[#CECFD3] bg-[#324d72] text-[#fff] rounded-lg p-3"
        type="submit"
        form="myForm"
      >
        Ажлын зар үүсгэх
      </button>

      {modal && (
        <div className="h-screen w-full fixed bg-[#000] bg-opacity-40 flex justify-center items-center top-0 left-0 z-50">
          <div className="bg-[#fff] rounded-xl px-8 py-6 relative w-[870px] h-[410px]">
            <div className="flex items-center justify-center gap-2 mb-8">
              <div className="w-[40px] h-[4px] rounded-md bg-[#1A1A1A]" />
              <div
                className={`w-[40px] h-[4px] rounded-md bg-[#1A1A1A] ${
                  page === 2 ? "" : " bg-opacity-30"
                }`}
              />
            </div>
            <button
              onClick={() => {
                setModal(false);
              }}
              className="absolute top-4 right-4 text-xl"
            >
              <IoCloseOutline />
            </button>
            {page === 1 ? (
              <>
                {" "}
                {images.length === 0 ? (
                  <div>
                    <div className="ms-20">
                      <p className="text-[#1E293B]">
                        Уурхай болон ажилчдын байрлах кемпийн зургийг оруулна
                        уу.
                      </p>
                      <p className="text-[#1E293B] text-sm">
                        Ингэснээр ажил горилогчид үүсэх тодорхойгүй байдлыг
                        багасгаж үнэлэмжийг нь өсгөх юм.
                      </p>
                    </div>
                    <div className="flex justify-center mt-6">
                      <div className="w-[354px] h-[200px] flex justify-center items-center outline-dashed rounded-xl bg-[#F9F9F9] outline-[#ABADB5] relative">
                        <input
                          type="file"
                          accept="image/*"
                          multiple
                          onChange={handleImageChange}
                          className="absolute inset-0 opacity-0 cursor-pointer"
                        />
                        <div>
                          <div className="flex justify-center">
                            <img src="/uurkhaichin/imgInsert.svg" alt="img" />
                          </div>
                          <p className="text-[#ABADB5] text-sm">
                            25 mb-с дээшгүй байх
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col justify-center items-center">
                    <div>
                      <p className="text-[#1E293B] mb-2">
                        Уурхай болон ажилчдын байрлах кемпийн зургийг оруулна
                        уу.
                      </p>
                      <div className="w-[450px] flex items-center gap-4 overflow-x-scroll border border-[#E2E8F0] py-2 px-3 rounded-xl">
                        {images.map((image, index) => (
                          <div
                            key={index}
                            className="relative w-[60px] h-[60px] rounded-lg flex-shrink-0"
                          >
                            <img
                              src={image}
                              alt={`uploaded-${index}`}
                              className="w-full h-full object-cover rounded-lg"
                            />
                            <button
                              onClick={() => handleImageDelete(index)}
                              className="absolute -top-1 -right-1 bg-red-500 text-white w-5 h-5 flex items-center justify-center rounded-full text-base"
                            >
                              x
                            </button>
                          </div>
                        ))}
                        <label
                          htmlFor="upload-more"
                          className="cursor-pointer flex-shrink-0"
                        >
                          <div className="w-[60px] h-[60px] rounded-lg flex items-center justify-center bg-[#F9F9F9]">
                            <div className="p-1 bg-[#777985] rounded-full text-white">
                              <FaPlus />
                            </div>
                          </div>
                          <input
                            id="upload-more"
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={handleImageChange}
                            className="hidden"
                          />
                        </label>
                      </div>

                      <div className="mt-8">
                        <p className="mb-2 text-[#1E293B]">
                          Кемп, хангамжийн тухай мэдээлэл оруулна уу.
                        </p>
                        <textarea
                          className="w-[450px] h-[72px] rounded-xl py-2 px-3 text-sm border-[#E2E8F0] text-[#F4F6FB]"
                          placeholder="Ж/нь: амралтын өрөө, фитнесс заалтай гэх мэт"
                          value={camp}
                          onChange={(e) => {
                            setCamp(e.target.value);
                          }}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="flex flex-col justify-center items-center">
                  <div>
                    <p className="text-[#1E293B]">
                      Ажилчдад олгогдох хангамжийн мэдээллийг оруулна уу.
                    </p>
                    <div className="w-[450px] h-[70px] rounded-xl border border-[#E2E8F0] flex flex-wrap gap-2 overflow-scroll py-2 px-3">
                      {check.map((item, index) => (
                        <div
                          onClick={() => handleCheckboxToggle(item)}
                          key={index}
                          className="flex items-center gap-2"
                        >
                          <input
                            checked={checked.includes(item)}
                            className="rounded-md bg-[#EBEBEB] border border-[#BCBCBC]"
                            type="checkbox"
                            readOnly
                          />
                          <p>{item}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="mt-8">
                    <p className="text-[#1E293B] mb-2">
                      Дээр дурдагдаагүй зүйл үлдсэн бол доор бичнэ үү.
                    </p>
                    <textarea
                      className="w-[450px] h-[72px] rounded-xl py-2 px-3 text-sm border-[#E2E8F0] text-[#F4F6FB]"
                      placeholder="Энд бичнэ үү."
                      value={other}
                      onChange={(e) => {
                        setOther(e.target.value);
                      }}
                    ></textarea>
                  </div>
                  {console.log(checked)}
                </div>
              </>
            )}
            <div className="absolute bottom-4 right-4 flex items-center gap-3">
              <button
                onClick={() => {
                  if (page === 1) {
                    setPage(2);
                  } else {
                    setModal(false);
                  }
                }}
                className="rounded-lg border border-[#CECFD3] py-2 px-3 text-sm text-[#1E293B]"
              >
                Одоо биш дараа
              </button>
              <button
                disabled={
                  page === 1
                    ? images.length === 0 || camp === ""
                    : checked.length === 0 || other === ""
                }
                onClick={() => {
                  if (page === 1) {
                    setPage(2);
                  } else {
                    setModal(false);
                  }
                }}
                className={`rounded-lg border border-[#CECFD3] ${
                  page === 1
                    ? images.length > 0 && camp !== ""
                      ? "bg-[#324D72]"
                      : "bg-[#CECFD3]"
                    : checked.length > 0 && other !== ""
                    ? "bg-[#324D72]"
                    : "bg-[#CECFD3]"
                } py-2 px-3 text-sm text-[#fff] flex items-center gap-2`}
              >
                {page === 1 ? (
                  <>
                    {" "}
                    Үргэлжлүүлэх <FaChevronRight className="text-base" />
                  </>
                ) : (
                  "Хадгалах"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateApplicant;
