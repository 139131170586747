import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import SoftSkillReport from "./softSkill/SoftSkillReport";
import SoftSkillResearch from "./softSkill/SoftSkillResearch";
import { getList } from "../../services/softSkill.service";
import Loading from "../../components/uncommon/Loading";

const SoftSkill = () => {
  const [activePage, setActivePage] = useState("report");
  const [list, setList] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleChangePage = (page) => {
    setActivePage(page);
    navigate(`${page}`);
  };

  useEffect(() => {
    getList()
      .then((data) => {
        setList(data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const currentPath = location.pathname;
    const pathParts = currentPath.split("/");
    const lastPart = pathParts[pathParts.length - 1];
    setActivePage(lastPart);
  }, [location]);

  const renderActivePage = () => {
    switch (activePage) {
      case "report":
        return <SoftSkillReport list={list} />;
      case "research":
        return <SoftSkillResearch list={list} />;
      default:
        return null;
    }
  };

  if (list === null) {
    return <Loading />;
  }

  return (
    <div className="w-10/12 m-auto pt-11">
      <div className="flex items-center justify-between">
        <p className="text-[22px] text-[#222]">Soft skill үнэлэх</p>
        <button
          onClick={() => {
            navigate("create");
          }}
          className="px-6 py-2 bg-[#324d72] rounded-xl text-white font-semibold text-sm flex items-center gap-2"
        >
          Судалгаа үүсгэх
          <FaPlus className="text-lg" />
        </button>
      </div>
      <div className="mt-5">
        <button
          onClick={() => {
            handleChangePage("report");
          }}
          className={`w-1/4 text-center py-2 text-lg font-bold ${
            activePage === "report"
              ? "text-[#FD6B3A] border-b-2 border-[#FD6B3A]"
              : ""
          }`}
        >
          Ерөнхий тайлан
        </button>

        <button
          onClick={() => {
            handleChangePage("research");
          }}
          className={`w-1/4 text-center py-2 text-lg font-bold ${
            activePage === "research"
              ? "text-[#FD6B3A] border-b-2 border-[#FD6B3A]"
              : ""
          }`}
        >
          Судалгаанууд
        </button>
      </div>
      <div>{renderActivePage()}</div>{" "}
    </div>
  );
};

export default SoftSkill;
