import React, { useEffect, useState, useTransition } from "react";
import { Bar } from "react-chartjs-2";
import { FaArrowLeft } from "react-icons/fa6";
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { getDep } from "../../../services/softSkill.service";
import Loading from "../../../components/uncommon/Loading";

const SoftSkillDep = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const { listId, id } = useParams();
  const [drop, setDrop] = useState(false);
  const [averages, setAverages] = useState({ mpi: 0, ci: 0 });
  const [selectedLabels, setSelectedLabels] = useState([]);

  useEffect(() => {
    getDep(listId, id).then((data) => {
      setData(data.data);
      const mpiAverage =
        data.data.reduce((sum, item) => sum + (item.mpi || 0), 0) /
        data.data.length;
      const ciAverage =
        data.data.reduce((sum, item) => sum + (item.ci || 0), 0) /
        data.data.length;
      setAverages({
        mpi: mpiAverage,
        ci: ciAverage,
      });
    });
  }, [listId, id]);

  const labels = [
    {
      name: "Бусдад итгэх хандлага",
      enName: "Trust in Others",
      field: "trusting",
    },
    {
      name: "Нарийвчилан дүгнэх",
      enName: "Analytical Thinking",
      field: "inductiveReasoning",
    },
    {
      name: "Эрдсэл хүлээх чадвар",
      enName: "Risk Tolerance",
      field: "riskTaking",
    },
    { name: "Сониуч зан", enName: "Curiosity", field: "curiosity" },
    {
      name: "Багаар ажиллах чадвар",
      enName: "Teamwork",
      field: "assertiveness",
    },
    {
      name: "Хэв таних",
      enName: "Pattern Recognition",
      field: "patternRecognition",
    },
    { name: "Суралцах хурд", enName: "Learning Speed", field: "learningRate" },
    {
      name: "Өөртөө итгэлтэй, шийдэмгий байдал",
      enName: "Confidence and Decisiveness",
      field: "assertiveness",
    },
    { name: "Төлөвлөх чадвар", enName: "Planning", field: "planning" },
    {
      name: "Шийдвэр гаргах чадвар",
      enName: "Decision Making",
      field: "decisionMaking",
    },
    {
      name: "Нухацтай сэтгэг",
      enName: "Critical Thinking",
      field: "criticalThinking",
    },
    {
      name: "Ажлын санах ой",
      enName: "Working Memory",
      field: "workingMemory",
    },
    { name: "Зорилго төлөвлөлт", enName: "Goal Setting", field: "goalSetting" },
    { name: "Анхаарал", enName: "Attention", field: "attention" },
    {
      name: "Боловсруулах хурд",
      enName: "Processing Speed",
      field: "processingSpeed",
    },
    {
      name: "Тоон сэтгэг",
      enName: "Quantitative Reasoning",
      field: "quantitativeReasoning",
    },
    {
      name: "Ерөнхийлөн дүгнэх",
      enName: "General Reasoning",
      field: "deductiveReasoning",
    },
    { name: "Туштай байдал", enName: "Consistency", field: "consistence" },
    {
      name: "Дасан зохицох чадвар",
      enName: "Adaptability",
      field: "adaptability",
    },
  ];

  if (data === null) {
    return <Loading />;
  }

  const calculateAverages = () => {
    const labelScores = labels.map((label) => {
      const scoreKeys = Object.keys(data[0]).filter((key) =>
        key.toLowerCase().includes(label.field.toLowerCase())
      );

      const averageScore =
        data.reduce((sum, item) => {
          return sum + (item[scoreKeys[0]] || 0);
        }, 0) / data.length;

      return {
        label: label.name,
        average: averageScore,
      };
    });

    return labelScores.sort((a, b) => b.average - a.average);
  };

  const sortedAverages = calculateAverages();

  const colors = sortedAverages.map((score, index) => {
    if (index < 5) {
      return "#34D399";
    } else if (index >= sortedAverages.length - 2) {
      return "#F87171";
    } else {
      return "#FBBF24";
    }
  });

  const datas = {
    labels: sortedAverages.map((item) => item.label),
    datasets: [
      {
        label: "Average score",
        data: sortedAverages.map((item) => item.average),
        backgroundColor: colors,
      },
    ],
  };

  const options = {
    indexAxis: "y",
    scales: {
      x: {
        beginAtZero: true,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const handleLabelSelect = (label) => {
    if (!selectedLabels.includes(label)) {
      setSelectedLabels([...selectedLabels, label]);
    }
  };

  return (
    <div className="w-10/12 pt-11 m-auto">
      <button
        onClick={() => {
          navigate("/company/softSkill/report");
        }}
        className="text-sm flex items-center gap-2"
      >
        <FaArrowLeft />
        Буцах
      </button>
      <div className="pt-10">
        <p className="text-[24px] text-[#222]">{data[0].departmentName}</p>

        <div className="flex items-center gap-10 pt-8">
          <div className="bg-[#fff] w-[160px] h-[74px] rounded-xl shadow-xl flex items-center px-4 py-3">
            <div>
              <p className="text-sm text-[#666874] mb-2">Дундаж MPI оноо</p>
              <p className="text-[24px] font-semibold text-[#324D72]">
                {averages.mpi.toFixed(0)}
              </p>
            </div>
          </div>
          <div className="bg-[#fff] w-[160px] h-[74px] rounded-xl shadow-xl flex items-center px-4 py-3">
            <div>
              <p className="text-sm text-[#666874] mb-2">Дундаж CI оноо</p>
              <p className="text-[24px] font-semibold text-[#324D72]">
                {averages.ci.toFixed(0)}
              </p>
            </div>
          </div>
        </div>
        <div className="flex w-full gap-10 items-start">
          <div className="w-[100%] max-w-4xl mx-auto mt-10">
            <Bar data={datas} options={options} />
          </div>
          <div className="w-[20%] mt-12">
            <div className="flex items-center gap-2">
              <div className="w-3 h-3 bg-[#34D399]" />
              <p>High score</p>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-3 h-3 bg-[#FBBF24]" />
              <p>Mid score</p>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-3 h-3 bg-[#F87171]" />
              <p>Low score</p>
            </div>
          </div>
        </div>
        <div className="mt-11">
          <p className="text-[#1E293B] font-semibold pb-10">
            Ажилтан тус бүрээр
          </p>
          <div className="w-full min-h-[300px] max-h-[600px] overflow-x-auto mb-20 pb-10">
            <table className="w-full min-w-max ">
              <thead className="bg-[#fff]">
                <tr>
                  <th className="px-3 py-3 font-semibold text-[#1E293B] text-sm">
                    Овог Нэр
                  </th>
                  <th className="px-3 py-3 font-semibold text-[#1E293B] text-sm">
                    MPI оноо
                  </th>
                  <th className="px-3 py-3 font-semibold text-[#1E293B] text-sm">
                    CI оноо
                  </th>
                  {selectedLabels.map((label, index) => (
                    <th
                      key={index}
                      className="px-3 py-3 font-semibold text-[#1E293B] text-sm"
                    >
                      {label}
                    </th>
                  ))}
                  <th className="px-3 py-3 relative">
                    <button
                      className="font-semibold text-[#1E293B] text-sm flex items-center gap-2"
                      onClick={() => {
                        setDrop(!drop);
                      }}
                    >
                      <p>Ур чадвар сонгоно уу.</p>
                      <IoIosArrowDown />
                    </button>
                    {drop && (
                      <div className="max-h-[200px] absolute top-10 overflow-y-auto bg-white shadow-custom p-2 rounded-lg z-30">
                        {labels.map((label, index) => (
                          <div
                            key={index}
                            className={`px-4 py-2 hover:bg-[#E9EDF5] border-b border-[#EFEFEF] hover:rounded-lg text-start cursor-pointer ${
                              selectedLabels.includes(label.name)
                                ? "bg-[#E9EDF5]"
                                : ""
                            }`}
                            onClick={() => {
                              handleLabelSelect(label.name);
                              setDrop(false);
                            }}
                          >
                            {label.name}
                          </div>
                        ))}
                      </div>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((items, index) => (
                  <tr
                    key={index}
                    className={`${index % 2 !== 0 ? "bg-[#fff]" : ""}`}
                  >
                    <td className="px-3 py-3">
                      {items.lastName}, {items.firstName}
                    </td>
                    <td className="px-3 py-3">{items.mpi.toFixed(0)}</td>
                    <td className="px-3 py-3">{items.ci.toFixed(0)}</td>
                    {selectedLabels.map((label, labelIndex) => {
                      const labelObj = labels.find((l) => l.name === label);

                      const score = labelObj ? items[labelObj.field] : null;

                      return (
                        <td key={labelIndex} className="px-3 py-3">
                          {score !== null && score !== undefined
                            ? score.toFixed(0)
                            : "-"}
                        </td>
                      );
                    })}
                    <td className="px-3 py-3">-</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoftSkillDep;
