import React, { useState } from "react";
import profs from "./profs.json";
import { IoCarOutline } from "react-icons/io5";
import { MdOutlineMailOutline, MdOutlinePhone } from "react-icons/md";

const CVBaaz = () => {
  const [prof, setProf] = useState("");
  const [con, setCon] = useState("");
  const [ros, setRos] = useState("");
  const [type, setType] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selected, setSelected] = useState(0);
  const height = window.innerHeight - 200;
  const height2 = window.innerHeight - 280;

  const data = [
    {
      name: "Машин механизмын оператор",
      condition: "rouster",
      experience: "3-5 жил",
      salary: "₮ 100К-150К",
      salaryType: "өдөрт",
      rouster: "14/14",
      rousterType: "Амралтаар",
      prof: false,
      certificat: true,
      company: ["Хан-Алтай ХХК", "Оюу-Толгой ХХК"],
      license: ["B", "C", "M"],
      equip: ["Экскаватор", "Ковш", "Дамп"],
      skills: [
        "Багийн ажиллагаа ба хамтын ажиллагаа",
        "Зөрчил зохицуулах",
        "Шинэ технологид дасан зохицох чадвар",
        "Аюулгүй ажиллагааг сахин мөрдөх",
      ],
      detailed:
        "Аюулгүй ажиллагааны сургалтуудад бүрэн хамрагдсан. Ямар нэгэн хорт зуршилүй, хариуцлагатай, цаг баримталдаг.",
    },
    {
      name: "Байгаль орчны мэргэжилтэн",
      condition: "ready",
      experience: "10-15 жил",
      salary: "₮ 200К-250К",
      salaryType: "өдөрт",
      rouster: "14/14",
      rousterType: "Амралтаар",
      prof: true,
      certificat: false,
      company: ["Хан-Алтай ХХК", "Оюу-Толгой ХХК"],
      license: [],
      equip: [" Хувийн хамгаалах хэрэгслийн (PPE) ашиглалт", "ХАБЭА"],
      skills: [
        "Багийн ажиллагаа ба хамтын ажиллагаа",
        "Зөрчил зохицуулах",
        "Шинэ технологид дасан зохицох чадвар",
        "Аюулгүй ажиллагааг сахин мөрдөх",
      ],
      detailed:
        "Аюулгүй ажиллагааны сургалтуудад бүрэн хамрагдсан. Ямар нэгэн хорт зуршилүй, хариуцлагатай, цаг баримталдаг.",
    },
    {
      name: "Суурин тоног төхөөрөмжийн оператор",
      condition: "retriment",
      experience: "5-10 жил",
      salary: "₮ 1.8 сая - 2.5 сая",
      salaryType: "сард",
      rouster: "14/14",
      rousterType: "Бүтэн цагаар",
      prof: false,
      certificat: true,
      company: ["Хан-Алтай ХХК", "Оюу-Толгой ХХК"],
      license: [],
      equip: ["Бутлуур", "Баяжуулах", "Насос"],
      skills: [
        "Багийн ажиллагаа ба хамтын ажиллагаа",
        "Зөрчил зохицуулах",
        "Шинэ технологид дасан зохицох чадвар",
        "Аюулгүй ажиллагааг сахин мөрдөх",
      ],
      detailed:
        "Аюулгүй ажиллагааны сургалтуудад бүрэн хамрагдсан. Ямар нэгэн хорт зуршилүй, хариуцлагатай, цаг баримталдаг.",
    },

    {
      name: "Цахилгааны инженер",
      condition: "fulltime",
      experience: "1-5жил",
      salary: "₮ 3 сая-5 сая",
      salaryType: "сард",
      rouster: "",
      rousterType: "Бүтэн цагаар",
      prof: false,
      certificat: true,
      company: ["Хан-Алтай ХХК", "Оюу-Толгой ХХК"],
      license: [],
      equip: [],
      skills: [
        "Багийн ажиллагаа ба хамтын ажиллагаа",
        "Зөрчил зохицуулах",
        "Шинэ технологид дасан зохицох чадвар",
        "Аюулгүй ажиллагааг сахин мөрдөх",
      ],
      detailed:
        "Аюулгүй ажиллагааны сургалтуудад бүрэн хамрагдсан. Ямар нэгэн хорт зуршилүй, хариуцлагатай, цаг баримталдаг.",
    },
    {
      name: "Цахилгааны инженер",
      condition: "fulltime",
      experience: "1-5жил",
      salary: "₮ 3 сая-5 сая",
      salaryType: "сард",
      rouster: "",
      rousterType: "Бүтэн цагаар",
      prof: false,
      certificat: true,
      company: ["Хан-Алтай ХХК", "Оюу-Толгой ХХК"],
      license: [],
      equip: [],
      skills: [
        "Багийн ажиллагаа ба хамтын ажиллагаа",
        "Зөрчил зохицуулах",
        "Шинэ технологид дасан зохицох чадвар",
        "Аюулгүй ажиллагааг сахин мөрдөх",
      ],
      detailed:
        "Аюулгүй ажиллагааны сургалтуудад бүрэн хамрагдсан. Ямар нэгэн хорт зуршилүй, хариуцлагатай, цаг баримталдаг.",
    },
    {
      name: "Цахилгааны инженер",
      condition: "fulltime",
      experience: "1-5жил",
      salary: "₮ 3 сая-5 сая",
      salaryType: "сард",
      rouster: "",
      rousterType: "Бүтэн цагаар",
      prof: false,
      certificat: true,
      company: ["Хан-Алтай ХХК", "Оюу-Толгой ХХК"],
      license: [],
      equip: [],
      skills: [
        "Багийн ажиллагаа ба хамтын ажиллагаа",
        "Зөрчил зохицуулах",
        "Шинэ технологид дасан зохицох чадвар",
        "Аюулгүй ажиллагааг сахин мөрдөх",
      ],
      detailed:
        "Аюулгүй ажиллагааны сургалтуудад бүрэн хамрагдсан. Ямар нэгэн хорт зуршилүй, хариуцлагатай, цаг баримталдаг.",
    },
  ];

  const handleFilter = (filterType) => {
    if (filterType === "") {
      setType("");
      setFilteredData(data);
      return;
    }

    setType(filterType);
    const filtered = data.filter((item) => item.condition === filterType);
    setFilteredData(filtered);
  };

  return (
    <div className="pt-11 w-[95%] m-auto">
      <div className="flex items-center justify-between mb-10">
        <div className="relative items-center">
          <svg
            className="absolute left-2 mt-2"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M8.245 14.2549H9.035L9.31499 13.9849C8.335 12.8449 7.745 11.3649 7.745 9.75488C7.745 6.16488 10.655 3.25488 14.245 3.25488C17.835 3.25488 20.745 6.16488 20.745 9.75488C20.745 13.3449 17.835 16.2549 14.245 16.2549C12.635 16.2549 11.155 15.6649 10.015 14.6849L9.745 14.9649V15.7549L4.745 20.7449L3.255 19.2549L8.245 14.2549ZM14.245 14.2549C16.735 14.2549 18.745 12.2449 18.745 9.75488C18.745 7.26488 16.735 5.25488 14.245 5.25488C11.755 5.25488 9.745 7.26488 9.745 9.75488C9.745 12.2449 11.755 14.2549 14.245 14.2549Z"
              fill="#575763"
            />
          </svg>
          <input
            className="py-2 pe-4 ps-8 w-[212px] text-sm bg-[#F9F9F9] border border-[#E5E6E8] rounded-lg"
            placeholder="Энд хайх утгаа оруулна уу."
            type="text"
          />
        </div>
        <div className="flex items-center gap-3">
          <select
            value={prof}
            onChange={(e) => {
              setProf(e.target.value);
            }}
            className="bg-[#F9F9F9] border border-[#E5E6E8] py-2 ps-3 pe-6 rounded-lg w-[98px]"
          >
            <option value="" disabled defaultChecked>
              Мэргэжил
            </option>
            {profs.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
          <select
            value={con}
            onChange={(e) => {
              setCon(e.target.value);
            }}
            className="bg-[#F9F9F9] border border-[#E5E6E8] py-2 ps-3 pe-6 rounded-lg w-[142px]"
          >
            <option value="" disabled defaultChecked>
              Ажиллах нөхцөл
            </option>
            <option value="office">Ирж ажиллах</option>
            <option value="remote">Зайнаас ажиллах</option>
            <option value="freelancer">Freelancer</option>
          </select>
          <select
            value={ros}
            onChange={(e) => {
              setRos(e.target.value);
            }}
            className="bg-[#F9F9F9] border border-[#E5E6E8] py-2 ps-3 pe-6 rounded-lg w-[79px]"
          >
            <option value="" disabled defaultChecked>
              Ростер
            </option>
            <option value="5/2">5/2</option>
            <option value="7/7">7/7</option>
            <option value="14/14">14/14</option>
            <option value="28/28">28/28</option>
            <option value="14/7">14/7</option>
            <option value="other">Бусад</option>
          </select>
        </div>
      </div>
      <div
        style={{
          height: `${height}px`,
        }}
        className="w-full  bg-[#fff] rounded-xl py-4 px-10 overflow-hidden"
      >
        <div className="flex items-center gap-4">
          <div
            onClick={() => {
              if (type === "fulltime") {
                handleFilter("");
              } else {
                handleFilter("fulltime");
              }
            }}
            className={`flex items-center gap-2 hover:underline cursor-pointer rounded-lg p-2 ${
              type === "fulltime" ? "border border-[#E5E6E8] bg-[#F9F9F9]" : ""
            }`}
          >
            <div className="w-3 h-3 bg-[#0BE000] rounded-full" />
            <p className="text-sm text-[#1E293B]">Бүтэн цагаар ажиллах</p>
          </div>
          <div
            onClick={() => {
              if (type === "retriment") {
                handleFilter("");
              } else {
                handleFilter("retriment");
              }
            }}
            className={`flex items-center gap-2 hover:underline cursor-pointer rounded-lg p-2 ${
              type === "retriment" ? "border border-[#E5E6E8] bg-[#F9F9F9]" : ""
            }`}
          >
            <div className="w-3 h-3 bg-[#0995E6] rounded-full" />
            <p className="text-sm text-[#1E293B]">Тэтгэвэрийн насны</p>
          </div>
          <div
            onClick={() => {
              if (type === "rouster") {
                handleFilter("");
              } else {
                handleFilter("rouster");
              }
            }}
            className={`flex items-center gap-2 hover:underline cursor-pointer rounded-lg p-2 ${
              type === "rouster" ? "border border-[#E5E6E8] bg-[#F9F9F9]" : ""
            }`}
          >
            <div
              style={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                background: "conic-gradient(#0BE000 0% 50%, #FFA500 50% 100%)",
              }}
            ></div>

            <p className="text-sm text-[#1E293B]">Амралтаар ажиллах</p>
          </div>
          <div
            onClick={() => {
              if (type === "ready") {
                handleFilter("");
              } else {
                handleFilter("ready");
              }
            }}
            className={`flex items-center gap-2 hover:underline cursor-pointer rounded-lg p-2 ${
              type === "ready" ? "border border-[#E5E6E8] bg-[#F9F9F9]" : ""
            }`}
          >
            <div className="w-3 h-3 bg-[#CECFD3] rounded-full" />
            <p className="text-sm text-[#1E293B]">Ажлын санал авахад бэлэн</p>
          </div>
        </div>
        <div className="mt-10 w-full flex items-center justify-between gap-10">
          <div
            style={{ height: `${height2}px` }}
            className="w-[45%] flex flex-col overflow-y-scroll gap-5 px-6"
          >
            {(type === "" ? data : filteredData).map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  setSelected(index);
                }}
                className={`w-full rounded-xl px-4 pt-4 relative ${
                  selected === index
                    ? "border border-[#324D72]"
                    : "shadow-custom"
                }`}
              >
                <div
                  style={{
                    background:
                      item.condition === "rouster"
                        ? "linear-gradient(to bottom, #0BE000 50%, #F88F00 50%)"
                        : item.condition === "fulltime"
                        ? "#0BE000"
                        : item.condition === "ready"
                        ? "#CECFD3"
                        : item.condition === "retriment"
                        ? "#0995E6"
                        : "",
                  }}
                  className={`absolute top-4 left-0 w-[4px] h-[24px] z-10`}
                />
                <div className="flex items-start justify-between mb-3">
                  <div>
                    <p className="font-semibold text-[#1E293B] w-[230px] text-wrap">
                      {item.name}
                    </p>
                    {item.certificat && (
                      <div className="flex items-center gap-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                        >
                          <path
                            d="M3.45455 10L2.59091 8.47619L0.954545 8.09524L1.11364 6.33333L0 5L1.11364 3.66667L0.954545 1.90476L2.59091 1.52381L3.45455 0L5 0.690476L6.54545 0L7.40909 1.52381L9.04545 1.90476L8.88636 3.66667L10 5L8.88636 6.33333L9.04545 8.09524L7.40909 8.47619L6.54545 10L5 9.30952L3.45455 10ZM4.52273 6.69048L7.09091 4L6.45455 3.30952L4.52273 5.33333L3.54545 4.33333L2.90909 5L4.52273 6.69048Z"
                            fill="#F88F00"
                          />
                        </svg>
                        <p className="text-[#636466] text-xs">
                          мэргэжлийн сертификаттай
                        </p>
                      </div>
                    )}
                  </div>
                  <div>
                    <p className="text-[#1E293B] text-sm font-semibold">
                      {item.experience}
                    </p>
                    <p className="text-xs text-[#636466]">Туршлага</p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  {item.equip.length > 0 && (
                    <>
                      {item.equip.map((jd, jndex) => (
                        <div
                          key={jndex}
                          className="bg-[#F9F9F9] px-[6px] py-[4px] text-xs text-[#636466] rounded-md"
                        >
                          {jd}
                        </div>
                      ))}
                    </>
                  )}
                  {item.license.length > 0 && (
                    <div className="bg-[#F9F9F9] px-[6px] py-[4px] text-xs text-[#636466] rounded-md flex items-center gap-0.5">
                      <IoCarOutline />{" "}
                      {item.license.map((jd, jndex) => (
                        <span key={jndex}>
                          {jd} {jndex + 1 === item.license.length && ","}
                        </span>
                      ))}
                    </div>
                  )}
                </div>
                <div className="border-t-2 py-3 mt-3 border-[#E5E6E8] flex items-center justify-between">
                  <div className=" flex items-center gap-1">
                    <p className="font-semibold text-xs text-[#324D72]">
                      {item.rousterType}{" "}
                    </p>
                    {item.rouster !== "" && (
                      <p className="flex items-center gap-1 font-semibold text-xs text-[#324D72]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="2"
                          height="3"
                          viewBox="0 0 2 3"
                          fill="none"
                        >
                          <circle cx="1" cy="1.5" r="1" fill="#324D72" />
                        </svg>{" "}
                        {item.rouster}
                      </p>
                    )}
                    {item.prof && (
                      <p className="flex items-center gap-1 font-semibold text-xs text-[#324D72]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="2"
                          height="3"
                          viewBox="0 0 2 3"
                          fill="none"
                        >
                          <circle cx="1" cy="1.5" r="1" fill="#324D72" />
                        </svg>
                        зөвлөх үйлчилгээ
                      </p>
                    )}
                  </div>
                  <div>
                    <p className="text-xs text-[#324d72] font-semibold">
                      {item.salary}{" "}
                      <span className="text-[#636466]">/{item.salaryType}</span>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div
            style={{ height: `${height2}px` }}
            className="w-[55%] shadow-custom rounded-xl p-8 relative overflow-y-scroll"
          >
            <div
              style={{
                background:
                  data[selected].condition === "rouster"
                    ? "linear-gradient(to bottom, #0BE000 50%, #F88F00 50%)"
                    : data[selected].condition === "fulltime"
                    ? "#0BE000"
                    : data[selected].condition === "ready"
                    ? "#CECFD3"
                    : data[selected].condition === "retriment"
                    ? "#0995E6"
                    : "",
              }}
            />
            <div className="mb-[56px]">
              <p className="text-[#1E293B] text-[20px] font-semibold mb-2">
                {data[selected].name}
              </p>
              {data[selected].certificat && (
                <div className="flex items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                  >
                    <path
                      d="M3.45455 10L2.59091 8.47619L0.954545 8.09524L1.11364 6.33333L0 5L1.11364 3.66667L0.954545 1.90476L2.59091 1.52381L3.45455 0L5 0.690476L6.54545 0L7.40909 1.52381L9.04545 1.90476L8.88636 3.66667L10 5L8.88636 6.33333L9.04545 8.09524L7.40909 8.47619L6.54545 10L5 9.30952L3.45455 10ZM4.52273 6.69048L7.09091 4L6.45455 3.30952L4.52273 5.33333L3.54545 4.33333L2.90909 5L4.52273 6.69048Z"
                      fill="#F88F00"
                    />
                  </svg>
                  <p className="text-[#636466] text-xs">
                    мэргэжлийн сертификаттай
                  </p>
                </div>
              )}
            </div>
            <div className="flex items-start justify-between mb-6">
              <div className="w-[40%]">
                <div className="flex items-center gap-4">
                  <p className="text-[#324D72] text-sm font-semibold">
                    Ажлын туршлага
                  </p>
                  <p className="text-[#324D72] text-sm font-semibold">
                    {data[selected].experience}
                  </p>
                </div>
                <hr className="bg-[#E5E6E8] my-3" />
                <ul className="text-[#1E293B] text-xs list-disc ms-8">
                  {data[selected].company?.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
              <div className="w-[2px] bg-[#E5E6E8] h-auto" />
              <div className="w-[40%]">
                <div className="flex items-center gap-4">
                  <p className="text-[#324D72] text-sm font-semibold">
                    Мэргэшил
                  </p>
                </div>
                <hr className="bg-[#E5E6E8] my-3" />
                {data[selected]?.license?.length > 0 ? (
                  <div className="flex items-center justify-between mb-2">
                    <p className="flex items-center gap-1 text-xs text-[#1E293B]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="3"
                        height="2"
                        viewBox="0 0 3 2"
                        fill="none"
                      >
                        <circle cx="1.5" cy="1" r="1" fill="#676767" />
                      </svg>
                      Жолооны үнэмлэх
                    </p>
                    <p className="text-xs text-[#636466]">
                      {data[selected]?.license?.map(
                        (item, index) =>
                          `${item}${
                            index + 1 === data[selected].license.length
                              ? ""
                              : ", "
                          }`
                      )}
                    </p>
                  </div>
                ) : (
                  ""
                )}
                <p className="flex items-center gap-1 text-xs text-[#1E293B]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="3"
                    height="2"
                    viewBox="0 0 3 2"
                    fill="none"
                  >
                    <circle cx="1.5" cy="1" r="1" fill="#676767" />
                  </svg>
                  Ажилладаг тоног төхөөрөмж
                </p>
                <ul className="text-[#1E293B] text-xs list-disc ms-8">
                  {data[selected].equip?.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="mb-6">
              <p className="font-semibold text-[#324D72] text-sm">Хүсэлт</p>
              <hr className="bg-[#E5E6E8] my-3" />
              <div>
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center gap-2">
                    <ul className="text-xs text-[#1E293B] list-disk">
                      <li>Ажиллахыг хүсч буй цаг:</li>
                    </ul>
                  </div>
                  <p className="text-xs font-light">
                    {data[selected].rousterType}
                  </p>
                </div>
              </div>
              <div>
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center gap-2">
                    <ul className="text-xs text-[#1E293B] list-disk">
                      <li>Ажиллахыг хүсч буй нөхцөл:</li>
                    </ul>
                  </div>
                  <p className="text-xs font-light">Очиж ажиллах</p>
                </div>
              </div>
              <div>
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center gap-2">
                    <ul className="text-xs text-[#1E293B] list-disk">
                      <li>Ажиллахыг хүсч буй хоног:</li>
                    </ul>
                  </div>
                  <p className="text-xs font-light">14 хоног</p>
                </div>
              </div>
              <div>
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center gap-2">
                    <ul className="text-xs text-[#1E293B] list-disk">
                      <li>Цалингийн хүлээлт:</li>
                    </ul>
                  </div>
                  <p className="text-xs font-light">
                    {data[selected].salary} / {data[selected].salaryType}
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-6">
              <p className="text-sm text-[#1E293B] font-semibold">
                Хувь хүний ур чадварууд
              </p>
              <hr className="bg-[#E5E6E8] my-3" />
              <div className="flex flex-wrap items-center gap-3">
                {data[selected].skills?.map((item, index) => (
                  <div
                    key={index}
                    className="bg-[#F9F9F9] rounded-md text-[#636466] text-xs py-[4px] px-[6px]"
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>{" "}
            <div className="mb-6">
              <p className="text-sm text-[#1E293B] font-semibold">
                Нэмэлт мэдээлэл
              </p>
              <hr className="bg-[#E5E6E8] my-3" />
              <p className="text-[#636466] text-xs">
                Аюулгүй ажиллагааны сургалтуудад бүрэн хамрагдсан. Ямар нэгэн
                хорт зуршилүй, хариуцлагатай, цаг баримталдаг.
              </p>
            </div>
            {data[selected].certificat && (
              <div className="mb-6">
                <p className="text-sm text-[#1E293B] font-semibold">
                  Мэргэжлийн сертификат
                </p>
                <hr className="bg-[#E5E6E8] my-3" />
                <div className="w-[255px] h-[141px] border border-[#E5E6E8] rounded-xl"></div>
              </div>
            )}
            <div>
              <div class="flex items-center w-full mb-4">
                <div class="w-[20px] border-t border-gray-300"></div>
                <span class="mx-4 text-sm font-medium text-[#324D72]">
                  Холбоо барих
                </span>
                <div class="flex-grow border-t border-gray-300"></div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <div className="border border-[#EFEFEF] w-[48px] h-[48px] rounded-full"></div>
                  <div>
                    <p className="text-xs text-[#324d72] font-semibold">
                      Хүрэлбаатар Бат-Орших
                    </p>
                    <p className="text-[10px] text-[#64748B]">
                      {data[selected].name}
                    </p>
                  </div>
                </div>
                <div>
                  <div className="flex items-center gap-2 text-[#3d4146] text-xs">
                    <MdOutlinePhone />
                    <p>+976 9999-9999</p>
                  </div>
                  <div className="flex items-center gap-2 text-[#1E293B] text-xs">
                    <MdOutlineMailOutline />
                    <p>khurel@gmail.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CVBaaz;
