import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SkillDashboard from "./dashboard/SkillDashboard";
import EmployeeDashboard from "./dashboard/EmployeeDashboard";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { t } = useTranslation();
  const [activePage, setActivePage] = useState("employee");
  const navigate = useNavigate;
  
  const handleChangePage = (page) => {
    setActivePage(page);
    navigate(`/dashboard/${page}`);
  };

  const renderActivePage = () => {
    switch (activePage) {
      case "skill":
        return <SkillDashboard />;
      case "employee":
        return <EmployeeDashboard />;
      default:
        return null;
    }
  };
  return (
    <div className="m-auto w-10/12 pt-11">
      <div>
        <p className="text-2xl font-black text-[#324d72]">
          {t("Dashboard.title")}
        </p>
        <hr className="my-5" />
      </div>
      <div>
        <div className="mt-10 ">
          <button
            onClick={() => {
              handleChangePage("employee");
            }}
            className={`w-1/4 text-center py-2 text-lg font-bold ${
              activePage === "employee"
                ? "text-[#FD6B3A] border-b-2 border-[#FD6B3A]"
                : ""
            }`}
          >
            {t("Dashboard.employee")}
          </button>
          <button
            onClick={() => {
              handleChangePage("skill");
            }}
            className={`w-1/4 text-center py-2 text-lg font-bold ${
              activePage === "skill"
                ? "text-[#FD6B3A] border-b-2 border-[#FD6B3A]"
                : ""
            }`}
          >
            {t("Dashboard.skill")}
          </button>
        </div>
        <div>{renderActivePage()}</div>
      </div>
    </div>
  );
};

export default Dashboard;
