import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { changeAssPlayerAppliedToInvited } from "../../services/assessment.service";
import {
  declinePlayer,
  getPlayer,
  inviteInterviewPlayer,
  waitListPlayer,
} from "../../services/assessmentPlayer.service";
import CV from "./PlayerProfile/CV";
import GameData from "./PlayerProfile/GameData";
import NewGameData from "./PlayerProfile/NewGameData";
import Loading from "../../components/uncommon/Loading";

const AssessmentPlayer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [activeTable, setActiveTable] = useState("GameData");
  const [isChecked, setIsChecked] = useState(false);
  const { t } = useTranslation();
  const handleToggle = () => {
    setIsChecked((prevState) => !prevState);
  };

  const options = [
    t("assessmentProfile.gameInvite"),
    t("assessmentProfile.inviteInterview"),
    // t("assessmentProfile.waitList"),
  ];

  const toggleStyle = {
    background: "linear-gradient(102deg, #F48D7E 8.63%, #D74C38 93.24%)",
    boxShadow: "2.554px 0px 15.327px 5.109px rgba(0, 0, 0, 0.10) inset",
  };

  useEffect(() => {
    getPlayer(id)
      .then((data) => {
        setData(data.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);

    if (option === t("assessmentProfile.gameInvite")) {
      if (data.ci === null || data.mpi === null) {
        toast.success(t("assessmentProfile.requestSuccess"));
        changeAssPlayerAppliedToInvited({ assPlayerId: id });
        setData({ ...data, completeType: "invited" });
      } else {
        toast.error(t("toast.Error"));
      }
    } else if (option === t("assessmentProfile.inviteInterview")) {
      inviteInterviewPlayer(id)
        .then((res) => {
          toast.success(t("assessmentProfile.invitedInterviewSuccess"));
        })
        .catch((err) => {
          toast.error(t("toast.Error"));
        });
    } else if (option === t("assessmentProfile.waitList")) {
      waitListPlayer(id)
        .then(() => {
          toast.success("");
        })
        .catch(() => {
          toast.error("");
        });
    }
  };
  const handleDecline = () => {
    declinePlayer(id)
      .then((res) => {
        toast.success(t("assessmentProfile.requestSuccess"));
      })
      .catch((err) => {
        toast.error(t("toast.Error"));
      });
  };

  const handleTableChange = (table) => {
    setActiveTable(table);
    navigate(`/player/${id}/${table}`);
  };

  if (data === null) {
    return (
      <div className="h-screen flex justify-center items-center">
        <Loading />
      </div>
    );
  }

  const renderActiveTable = () => {
    switch (activeTable) {
      case "GameData":
        return isChecked ? <GameData /> : <NewGameData />;
      case "CV":
        return <CV id={id} fileUrl={data.fileUrl} />;
      default:
        return null;
    }
  };

  return (
    <div className="m-auto w-10/12 pt-11">
      <div>
        <div className="flex gap-5 items-center">
          <p className="text-gray-500">
            <Link to="/">{t("Home.jobs")}</Link>
          </p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
          >
            <path
              d="M7.21875 3.94287L13.2187 9.94287L7.21875 15.9429"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="text-gray-500">
            <Link to={`/customer/assessments/${data.assessmentId}`}>
              {data.assessmentName}
            </Link>
          </p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
          >
            <path
              d="M7.21875 3.94287L13.2187 9.94287L7.21875 15.9429"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="text-[#324d72] underline underline-offset-4">
            {data.name} {data.lastName}
          </p>
        </div>
        <div className=" w-full ">
          <p className="text-4xl font-bold pt-5 ">
            {data.name} {data.lastName}
          </p>
        </div>
        <div className="flex border-y-2 pt-5 pb-10 justify-between mt-5">
          <div>
            <div className="flex items-center gap-5">
              <p className="flex items-center text-[16px]">
                <span className="me-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 25 25"
                    fill="none"
                  >
                    <path
                      d="M14.3 6.70801C15.2768 6.89857 16.1744 7.37626 16.8781 8.07994C17.5818 8.78362 18.0595 9.68127 18.25 10.658M14.3 2.70801C16.3293 2.93344 18.2216 3.84218 19.6663 5.28502C21.1109 6.72785 22.0221 8.61902 22.25 10.648M18.75 21.708C10.1896 21.708 3.25 14.7684 3.25 6.20801C3.25 5.82179 3.26413 5.43887 3.29189 5.05973C3.32375 4.62463 3.33968 4.40707 3.4537 4.20904C3.54814 4.04502 3.7155 3.88947 3.88598 3.80726C4.09181 3.70801 4.33188 3.70801 4.81201 3.70801H7.62932C8.03308 3.70801 8.23496 3.70801 8.40802 3.77446C8.56089 3.83316 8.69701 3.9285 8.80442 4.0521C8.92601 4.19204 8.995 4.38177 9.13299 4.76122L10.2991 7.96805C10.4596 8.40953 10.5399 8.63027 10.5263 8.83971C10.5143 9.02438 10.4512 9.20209 10.3442 9.35307C10.2229 9.52429 10.0215 9.64514 9.61863 9.88683L8.25 10.708C9.4519 13.3569 11.6001 15.5079 14.25 16.708L15.0712 15.3394C15.3129 14.9366 15.4337 14.7351 15.6049 14.6138C15.7559 14.5068 15.9336 14.4437 16.1183 14.4317C16.3277 14.4181 16.5485 14.4984 16.99 14.6589L20.1968 15.825C20.5762 15.963 20.766 16.032 20.9059 16.1536C21.0295 16.261 21.1249 16.3971 21.1835 16.55C21.25 16.7231 21.25 16.9249 21.25 17.3287V20.146C21.25 20.6261 21.25 20.8662 21.1507 21.072C21.0685 21.2425 20.913 21.4099 20.749 21.5043C20.5509 21.6183 20.3334 21.6343 19.8983 21.6661C19.5191 21.6939 19.1362 21.708 18.75 21.708Z"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                {data.phoneNumber ? data.phoneNumber : "0000 - 0000"}
              </p>
              <p className="flex items-center text-[16px]">
                <span className="me-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="21"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      d="M21 8.70801L17.4392 10.6862C15.454 11.7891 14.4614 12.3406 13.4102 12.5568C12.4798 12.7481 11.5202 12.7481 10.5898 12.5568C9.53864 12.3406 8.54603 11.7891 6.5608 10.6862L3 8.70801M6.2 19.708H17.8C18.9201 19.708 19.4802 19.708 19.908 19.49C20.2843 19.2983 20.5903 18.9923 20.782 18.616C21 18.1882 21 17.6281 21 16.508V8.90801C21 7.7879 21 7.22785 20.782 6.80003C20.5903 6.4237 20.2843 6.11774 19.908 5.92599C19.4802 5.70801 18.9201 5.70801 17.8 5.70801H6.2C5.0799 5.70801 4.51984 5.70801 4.09202 5.92599C3.71569 6.11774 3.40973 6.4237 3.21799 6.80003C3 7.22785 3 7.7879 3 8.90801V16.508C3 17.6281 3 18.1882 3.21799 18.616C3.40973 18.9923 3.71569 19.2983 4.09202 19.49C4.51984 19.708 5.07989 19.708 6.2 19.708Z"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                {data.email}
              </p>
              {/* <p className="flex items-center text-[16px]">
                <span className="me-2">
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 25 25"
                    fill="none"
                  >
                    <path
                      d="M9.41586 18.3584C9.1761 18.5822 8.99055 18.732 8.80093 18.8415C7.87273 19.3774 6.72914 19.3774 5.80093 18.8415C5.45889 18.644 5.13009 18.3152 4.47251 17.6576C3.81492 17.0001 3.48612 16.6713 3.28864 16.3292C2.75274 15.401 2.75274 14.2574 3.28864 13.3292C3.48612 12.9872 3.81492 12.6584 4.4725 12.0008L7.30093 9.17236C7.95852 8.51477 8.28732 8.18598 8.62936 7.9885C9.55756 7.4526 10.7012 7.4526 11.6294 7.9885C11.9714 8.18598 12.3002 8.51477 12.9578 9.17236C13.6154 9.82995 13.9442 10.1587 14.1416 10.5008C14.6775 11.429 14.6775 12.5726 14.1416 13.5008C14.0322 13.6904 13.8823 13.876 13.6585 14.1157M10.8429 11.3001C10.619 11.5398 10.4692 11.7254 10.3597 11.915C9.82381 12.8432 9.82381 13.9868 10.3597 14.915C10.5572 15.257 10.886 15.5858 11.5436 16.2434C12.2012 16.901 12.53 17.2298 12.872 17.4273C13.8002 17.9632 14.9438 17.9632 15.872 17.4273C16.214 17.2298 16.5428 16.901 17.2004 16.2434L20.0289 13.415C20.6864 12.7574 21.0152 12.4286 21.2127 12.0866C21.7486 11.1584 21.7486 10.0148 21.2127 9.08658C21.0152 8.74453 20.6864 8.41574 20.0289 7.75815C19.3713 7.10056 19.0425 6.77177 18.7004 6.57429C17.7722 6.03839 16.6286 6.03839 15.7004 6.57429C15.5108 6.68376 15.3253 6.8336 15.0855 7.05744"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </span>
                {data.social ? data.social : ""}
              </p> */}
              {/* <p className="flex items-center">
                <span className="me-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M8 5.00005C7.01165 5.00082 6.49359 5.01338 6.09202 5.21799C5.71569 5.40973 5.40973 5.71569 5.21799 6.09202C5 6.51984 5 7.07989 5 8.2V17.8C5 18.9201 5 19.4802 5.21799 19.908C5.40973 20.2843 5.71569 20.5903 6.09202 20.782C6.51984 21 7.07989 21 8.2 21H15.8C16.9201 21 17.4802 21 17.908 20.782C18.2843 20.5903 18.5903 20.2843 18.782 19.908C19 19.4802 19 18.9201 19 17.8V8.2C19 7.07989 19 6.51984 18.782 6.09202C18.5903 5.71569 18.2843 5.40973 17.908 5.21799C17.5064 5.01338 16.9884 5.00082 16 5.00005M8 5.00005V7H16V5.00005M8 5.00005V4.70711C8 4.25435 8.17986 3.82014 8.5 3.5C8.82014 3.17986 9.25435 3 9.70711 3H14.2929C14.7456 3 15.1799 3.17986 15.5 3.5C15.8201 3.82014 16 4.25435 16 4.70711V5.00005"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                Анкеттай танилцах
              </p> */}
            </div>
            <div className="mt-10 flex  items-center">
              <div className="w-full">
                <button
                  className={`text-[24px]  font-bold  me-20 px-5 py-2 ${
                    activeTable === "GameData"
                      ? " text-[#FD6B3A]  border-b-2 border-[#FD6B3A] "
                      : ""
                  }`}
                  onClick={() => handleTableChange("GameData")}
                >
                  {t("assessmentProfile.gameResult")}
                </button>
                <button
                  className={`text-[24px] font-bold  me-10 px-10 py-2 ${
                    activeTable === "CV"
                      ? " text-[#FD6B3A]  border-b-2 border-[#FD6B3A] "
                      : ""
                  }`}
                  onClick={() => handleTableChange("CV")}
                >
                  CV
                </button>
              </div>
              <div className="w-[230px]">
                {data.completeType === "applied" ? (
                  <div className="flex items-center">
                    <img
                      src="/icons/orangeDot.png"
                      width="10px"
                      height="10px"
                    />
                    <p className="ms-2">{t("Assessment.applied")}</p>
                  </div>
                ) : data.completeType === "completed" ? (
                  <div className="flex items-center">
                    <img
                      src="/icons/greenEllipse.png"
                      width="10px"
                      height="10px"
                    />
                    <p className="ms-2">{t("Assessment.completed")}</p>
                  </div>
                ) : data.completeType === "started" ? (
                  <div className="flex items-center">
                    <img src="/icons/blueDot.png" width="10px" height="10px" />
                    <p className="ms-2">{t("Assessment.started")}</p>
                  </div>
                ) : data.completeType === "declined" ? (
                  <div className="flex items-center">
                    <img src="/icons/redDot.png" width="10px" height="10px" />
                    <p className="ms-2">{t("Assessment.declined")}</p>
                  </div>
                ) : data.completeType === "interview" ? (
                  <div className="flex items-center">
                    <img
                      src="/icons/purpleDot.png"
                      width="10px"
                      height="10px"
                    />
                    <p className="ms-2">{t("Assessment.interview")}</p>
                  </div>
                ) : (
                  <div className="flex items-center">
                    <img src="/icons/grayDot.png" width="10px" height="10px" />
                    <p className="ms-2">{t("Assessment.sendInvitation")}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col ">
            <div className="relative inline-block text-left">
              <div>
                <span className="rounded-md shadow-sm">
                  <button
                    type="button"
                    className="text-xl text-white bg-[#324d72] px-4 py-2 focus:ring-2 flex items-center justify-center rounded-lg"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    {t("assessmentProfile.nextStage")}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                    >
                      <path
                        d="M6 9.24365L12 15.2437L18 9.24365"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </span>
              </div>
              {isOpen && (
                <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div
                    className="py-1"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    {options.map((option, index) => {
                      if (
                        (index === 0 && data.ci !== null) ||
                        (index === 0 && data.completeType === "invited") ||
                        (index === 0 && data.completeType === "completed")
                      ) {
                        return null;
                      } else {
                        return (
                          <button
                            key={index}
                            onClick={() => handleSelect(option)}
                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            role="menuitem"
                          >
                            {option}
                          </button>
                        );
                      }
                    })}
                  </div>
                </div>
              )}
            </div>
            <button
              onClick={handleDecline}
              className="text-xl px-4 py-2 flex items-center justify-center rounded-lg text-red bg-white border border-[#F18B8B] mt-2 text-[#F18B8B] focus:ring-4"
            >
              {t("assessmentProfile.declined")}
            </button>
          </div>
        </div>
      </div>

      {activeTable === "GameData" && (
        <label className="flex items-center cursor-pointer">
          <div className="mr-3 mt-2 font-bold">
            {t("assessmentProfile.newReport")}
          </div>
          <div className="relative mt-2">
            <input
              type="checkbox"
              className="sr-only"
              checked={isChecked}
              onChange={handleToggle}
            />
            <div
              className={`w-12 h-7  rounded-2xl shadow-inner  `}
              style={isChecked ? toggleStyle : { backgroundColor: "#879DAB" }}
            ></div>
            <div
              className={`
                  bg-[#fff] m-1 absolute w-5 h-5 rounded-full shadow inset-y-0 left-0 focus:outline-none transition-transform duration-300 transform ${
                    isChecked ? "translate-x-full" : "translate-x-0"
                  }`}
            ></div>
          </div>
          <div className="ml-3 mt-2 font-bold">
            {t("assessmentProfile.oldReport")}
          </div>
        </label>
      )}
      <div className="flex">
        <div className="w-full me-10">{renderActiveTable()}</div>
        {/* <div className="w-3/12">adas</div> */}
      </div>
      <ToastContainer />
    </div>
  );
};

export default AssessmentPlayer;
