import React, { useState } from "react";
import { FaAngleRight } from "react-icons/fa6";
import { FaAngleLeft } from "react-icons/fa6";
import dayjs from "dayjs";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const InternalEmp = () => {
  const [startDate, setStartDate] = useState(
    dayjs().startOf("week").add(1, "day")
  );

  const data = [
    { month: "01", value: 3, name: "Survey name", completion: 70, count: 25 },
    { month: "02", value: 2, name: "Survey name", completion: 50, count: 20 },
    { month: "03", value: 3, name: "Survey name", completion: 60, count: 22 },
    { month: "04", value: 4, name: "Survey name", completion: 80, count: 30 },
    { month: "05", value: 3, name: "Survey name", completion: 65, count: 28 },
    { month: "06", value: 4, name: "Survey name", completion: 70, count: 25 },
  ];

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "#333",
            color: "#fff",
            padding: "10px",
            borderRadius: "8px",
          }}
        >
          <p>{payload[0].payload.name}</p>
          <p>👥 {payload[0].payload.count}</p>
          <p>{payload[0].payload.completion}% гүйцэтгэл</p>
        </div>
      );
    }
    return null;
  };

  const handleNextWeek = () => {
    setStartDate((prevDate) => prevDate.add(7, "day"));
  };

  const handlePrevWeek = () => {
    setStartDate((prevDate) => prevDate.subtract(7, "day"));
  };

  const daysOfWeek = Array.from({ length: 7 }, (_, index) =>
    startDate.add(index, "day")
  );

  return (
    <div>
      <div className="p-4 bg-[#fff] w-full rounded-xl mb-6">
        <div className="mb-5 flex items-center justify-between">
          <p className="text-[#1E293B]">Удахгүй тохиох эвентүүд</p>
          <div className="flex items-center bg-[#F9F9F9] py-1 px-2 rounded-lg gap-2">
            <button onClick={handlePrevWeek}>
              <FaAngleLeft className="text-[#1E293B]" />
            </button>
            <p className="text-[#1E293B] text-sm">
              {daysOfWeek[0].format("D MMM")} -{" "}
              {daysOfWeek[6].format("D MMM YYYY")}
            </p>
            <button onClick={handleNextWeek}>
              <FaAngleRight className="text-[#1E293B]" />
            </button>
          </div>
        </div>
        <div className="flex items-center justify-center rounded-xl overflow-x-hidden">
          {daysOfWeek.map((day, index) => (
            <div
              key={index}
              className={`border border-[#D9D9D9] ${
                index === 0 ? "rounded-tl-xl rounded-bl-xl" : ""
              } ${
                index === 6 ? "rounded-tr-xl rounded-br-xl" : ""
              } w-[123px] h-[147px]`}
            >
              <div
                className={`bg-[#F9F9F9] w-full text-center px-5 py-3 h-[48px] ${
                  index === 0 ? "rounded-tl-xl" : ""
                } ${index === 6 ? "rounded-tr-xl" : ""}`}
              >
                <p className="text-[#1E293B] text-sm font-semibold">
                  {day.format("ddd D")}
                </p>
              </div>
              <div className="w-full h-[98px]"></div>
            </div>
          ))}
        </div>
      </div>
      <div className="p-4 bg-[#fff] w-full rounded-xl mb-6">
        <div className="flex items-center justify-between mb-5">
          <p className="text-[#1E293B]">Пульс судалгаа </p>
        </div>
        <div style={{ width: "100%", height: 200 }}>
          <ResponsiveContainer>
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis
                dataKey="month"
                axisLine={false}
                tickLine={false}
                tick={({ payload, x, y }) => {
                  const formattedValue = parseInt(payload.value, 10);
                  return (
                    <text
                      x={x}
                      y={y + 10}
                      fill={"#777985"}
                      fontSize={14}
                      textAnchor="middle"
                    >
                      {formattedValue}
                    </text>
                  );
                }}
              />
              <YAxis
                domain={[1, 5]}
                axisLine={false}
                tickLine={false}
                tickFormatter={(tick) => tick}
                tick={({ payload, x, y }) => {
                  const colors = {
                    1: "#4CAF50",
                    2: "#8BC34A",
                    3: "#FFC107",
                    4: "#FF9800",
                    5: "#F44336",
                  };

                  return (
                    <text
                      x={x}
                      y={y}
                      textAnchor="end"
                      fill={colors[payload.value]}
                      fontSize={12}
                    >
                      {payload.value}
                    </text>
                  );
                }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Line
                type="linear"
                dataKey="value"
                stroke="#5A6ACF"
                strokeWidth={2}
                dot={{ r: 5, fill: "#5A6ACF" }}
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default InternalEmp;
