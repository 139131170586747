import api from "../helper/request";

const createSoft = (obj) => api.post("/soft-skill/create", obj);

const getEmail = () => api.get("/email-template/soft-skill");

const getList = () => api.get("/soft-skill/list");

const getReport = (id) => api.get(`/soft-skill/report/${id}`);

const getDep = (listId, id) => api.get(`/soft-skill/report/${listId}/${id}`);

const delSoftSkill = (id) => api.delete(`/soft-skill/delete/${id}`);

export { createSoft, getEmail, getReport, getList, getDep, delSoftSkill };
