import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
} from "chart.js";
import React, { useEffect, useRef, useState } from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import { IoIosArrowDown } from "react-icons/io";
import { IoFilter } from "react-icons/io5";
import { MdOutlineTheaterComedy } from "react-icons/md";
import "../../../css/style.scss";
import { useTranslation } from "react-i18next";
import MainButton from "../../../components/buttons/MainButton";
import * as htmlToImage from "html-to-image";
import parse from "html-react-parser";
import Loading from "../../../components/uncommon/Loading";
import { createSurveyAiTxt } from "../../../services/survey.service";
import { BsStars } from "react-icons/bs";
import { IoIosClose } from "react-icons/io";
import { useParams } from "react-router-dom";

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
  // ChartDataLabels
);

const SurveySummary = (datas) => {
  const [maxHeight, setMaxHeight] = useState(0);
  const departmentRef = useRef(null);
  const [accordion, setAccordion] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [depId, setDepId] = useState(0);
  const [topId, setTopId] = useState(0);
  const [questId, setQuestId] = useState(0);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const data = datas.datas;
  const [selectedQuestionId, setSelectedQuestionId] = useState(
    data.topics[0].questions[0].questionId
  );
  const [selectedDataPoints, setSelectedDataPoints] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedSecondData, setSelectedSecondData] = useState(null);
  const [aiSug, setAiSug] = useState(null);
  const [ai, setAi] = useState(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [aiDatas, setAiDatas] = useState(null);
  const { t } = useTranslation();
  const [openAccordions, setOpenAccordions] = useState([]);
  const [aiInstructTxt, setAiInstructTxt] = useState("");
  const [filteredTopics, setFilteredTopics] = useState(null);
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    setAiInstructTxt(datas?.datas?.aiText);
    if (topId !== 0 && depId !== 0) {
      TopicPercentages(topId, depId);
    } else if (questId !== 0 && depId !== 0) {
      QuestionPercentages(questId, depId);
    }
  }, [topId, depId, questId]);

  useEffect(() => {
    if (ai !== null) {
      const dataLines = ai.split("\n").filter((line) => line.trim() !== "");
      setAiSug(dataLines);
    } else if (aiDatas?.aiSuggest !== null) {
      const dataLines = aiDatas?.aiSuggest
        .split("\n")
        .filter((line) => line.trim() !== "");
      setAiSug(dataLines);
    } else {
      setAi(null);
      setAiSug(null);
    }
  }, [ai, aiDatas]);

  useEffect(() => {
    const filteredTopics =
      selectedTopics.length > 0
        ? data.topics.filter((topic) => selectedTopics.includes(topic.topicId))
        : data.topics;

    setFilteredTopics(filteredTopics);
  }, [data, selectedTopics]);

  useEffect(() => {
    if (departmentRef.current) {
      setMaxHeight(departmentRef.current.clientHeight);
    }
  }, [filteredTopics]);

  const TopicPercentages = (topicId, departmentId) => {
    const topic = data.topics.find((topic) => topic.topicId === topicId);
    if (!topic) return null;

    let score1Count = 0;
    let score2Count = 0;
    let score3Count = 0;
    let score4Count = 0;
    let score5Count = 0;
    let departmentName = "";

    topic.questions?.forEach((question) => {
      const department = question.departments.find(
        (dep) => dep.departmentId === departmentId
      );
      if (department) {
        score1Count += department.score1Count;
        score2Count += department.score2Count;
        score3Count += department.score3Count;
        score4Count += department.score4Count;
        score5Count += department.score5Count;
        departmentName = department.departmentName;
      }
    });

    const totalResponses =
      score1Count + score2Count + score3Count + score4Count + score5Count;

    const percentScore1 = (score1Count / totalResponses) * 100;
    const percentScore2 = (score2Count / totalResponses) * 100;
    const percentScore3 = (score3Count / totalResponses) * 100;
    const percentScore4 = (score4Count / totalResponses) * 100;
    const percentScore5 = (score5Count / totalResponses) * 100;

    const newData = {
      departmentName: departmentName,
      topicName: topic.name,
      percentScore1,
      percentScore2,
      percentScore3,
      percentScore4,
      percentScore5,
    };

    setSelectedData(newData);
  };

  const QuestionPercentages = (questionId, departmentId) => {
    const question = data.topics
      .flatMap((topic) => topic.questions)
      .find((q) => q.questionId === parseInt(questionId));

    if (!question) {
      console.error(`Question with ID ${questionId} not found.`);
      return null;
    }

    const department = question.departments.find(
      (dep) => dep.departmentId === parseInt(departmentId)
    );

    if (!department) {
      console.error(
        `Department with ID ${departmentId} not found for question ${questionId}.`
      );
      return null;
    }

    const totalResponses =
      department.score1Count +
      department.score2Count +
      department.score3Count +
      department.score4Count +
      department.score5Count;

    const percentScore1 = (department.score1Count / totalResponses) * 100;
    const percentScore2 = (department.score2Count / totalResponses) * 100;
    const percentScore3 = (department.score3Count / totalResponses) * 100;
    const percentScore4 = (department.score4Count / totalResponses) * 100;
    const percentScore5 = (department.score5Count / totalResponses) * 100;

    const newData = {
      departmentName: department.departmentName,
      topicName: question.questionName,
      percentScore1,
      percentScore2,
      percentScore3,
      percentScore4,
      percentScore5,
    };

    // Set selected data in state
    setSelectedData(newData);
  };

  const handleSelectChange = (event) => {
    setSelectedQuestionId(event.target.value);
  };

  const handleTopicCheckboxChange = (topicId) => {
    accordion === topicId ? setAccordion(0) : setAccordion(topicId);
    if (selectedTopics.includes(topicId)) {
      setSelectedTopics(selectedTopics.filter((id) => id !== topicId));
    } else {
      setSelectedTopics([...selectedTopics, topicId]);
    }
  };

  const getFilteredChartData = (questionId) => {
    const question = data.topics
      .flatMap((topic) => topic.questions)
      .find((q) => q.questionId === parseInt(questionId));
    if (!question) return {};

    const labels = ["1: ", "2: ", "3: ", "4: ", "5: "];
    const scores = [
      question.departments.reduce((sum, dep) => sum + dep.score1Count, 0),
      question.departments.reduce((sum, dep) => sum + dep.score2Count, 0),
      question.departments.reduce((sum, dep) => sum + dep.score3Count, 0),
      question.departments.reduce((sum, dep) => sum + dep.score4Count, 0),
      question.departments.reduce((sum, dep) => sum + dep.score5Count, 0),
    ];

    // Calculate total responses for percentage calculation
    const totalResponses = scores.reduce((sum, score) => sum + score, 0);
    const percentages = scores.map(
      (score) => ((score / totalResponses) * 100).toFixed(0) + "%"
    );

    return {
      labels: labels,
      datasets: [
        {
          data: scores,
          backgroundColor: [
            "#FF5F6E",
            "#FFBD70",
            "#FFE070",
            "#7EEFB2",
            "#00D97D",
          ],
          cutout: "60%",
          borderRadius: 8,
        },
      ],
      percentages: percentages,
    };
  };

  const filteredChartData = getFilteredChartData(selectedQuestionId);

  if (data === null) {
    return <div>Хариу алга</div>;
  }

  const handleSubmit = () => {
    setLoading(true);
    let process = 1;
    const interval = setInterval(() => {
      if (process < 100) {
        setProgress(process);
        process++;
      } else {
        clearInterval(interval);
      }
    }, 1200);
  };

  const toggleAccordion = (id) => {
    setOpenAccordions((prev) =>
      prev.includes(id)
        ? prev.filter((accordionId) => accordionId !== id)
        : [...prev, id]
    );
  };

  const aiInstruct = async () => {
    var node = document.getElementsByClassName("downloadSurveyImg")[0]; // Get the first element

    if (node) {
      htmlToImage
        .toPng(node)
        .then(async function (dataUrl) {
          setLoading(true);

          await fetch(dataUrl)
            .then((res) => res.blob())
            .then((blob) => {
              const formdata = new FormData();
              formdata.append(
                "text",
                "Судалнааны үр дүнд, товч дүгнэлт хийгээд цаашид юун дээр анхаарах тал дээр зөвөлгөө өг"
              );
              formdata.append("image", blob, "converted_image.png");

              const requestOptions = {
                method: "POST",
                body: formdata,
                redirect: "follow",
              };

              return fetch(
                "https://oneplaceaiwithimage.azurewebsites.net/api/ImageTextProcessor",
                requestOptions
              );
            })
            .then((response) => response.text())
            .then((result) => {
              setAiInstructTxt(JSON.parse(result).choices[0].message.content);
              const obj = {
                surveyId: datas.surveyId,
                text: JSON.parse(result).choices[0].message.content,
              };
              createSurveyAiTxt(obj);
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false)); // Ensure loading is stopped once all async tasks are done
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
          setLoading(false); // In case of error, stop the loading indicator
        });
    } else {
      console.error("Element not found!");
    }
  };

  const convertToHTML = (text) => {
    // Convert "### " to <br/><strong>
    text = text
      .replace(/###\s/g, "<br/><strong>")
      .replace(/\n/g, "</strong><br/>");

    // Convert "**some text**" to <strong>some text</strong>
    text = text.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");

    // Convert newlines to <br/>
    text = text.replace(/\n/g, "<br/>");

    return text;
  };

  if (filteredTopics === null) {
    return <Loading />;
  }

  const splitDepartmentName = (name) => {
    // Split words by space
    const words = name.split(" ");

    // Process each word
    return words
      .map((word) => {
        if (word.length > 7) {
          return word.slice(0, 7) + "- " + word.slice(7); // Split word and add a hyphen
        }
        return word; // Return word as is if its length <= 7
      })
      .join(" ");
  };

  return (
    <div className="bg-[#F3F4F8] min-h-screen">
      {loading ? <Loading /> : null}
      {aiInstructTxt != null && aiInstructTxt !== "" ? (
        <div className="bg-[#fff] py-3 rounded-xl mt-10 p-5">
          <div>{parse(convertToHTML(aiInstructTxt))}</div>
        </div>
      ) : null}
      <div className="my-5">
        {/* <div className="flex items-center gap-2">
              <MdOutlineTheaterComedy />
              <p className="text-[#222] text-sm">Таны оруулсан тайлбар</p>
            </div>{" "}
            <div className="mt-2 mb-2">
              <p className="text-xs text-[#222] opacity-70">
                <span className="text-[#324D72] text-lg font-semibold">1</span>{" "}
                - Бүрэн санал нийлж байна
              </p>
              <p className="text-xs text-[#222] opacity-70">
                <span className="text-[#324D72] text-lg font-semibold">5</span>{" "}
                - Огт санал нийлдэггүй
              </p>
            </div> */}
        <div>
          <MainButton
            lIcon={<BsStars />}
            label={"AI зөлөмж"}
            color={"bg-main-blue"}
            labelColor={"text-white"}
            onClick={() => {
              aiInstruct();
            }}
          />
        </div>
      </div>

      <div className="relative">
        <button
          className="flex items-center justify-between bg-white mb-5 gap-4 rounded-xl px-2 py-1 "
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="flex items-center gap-2">
            <IoFilter />
            <p className="text-[#222] text-sm">{t("Survey.filter")}</p>
          </div>
          <IoIosArrowDown
            className={` duration-700 transition-transform ${
              isOpen ? "rotate-180" : ""
            }`}
          />
        </button>
        {isOpen && (
          <div className="mt-2 absolute left-0 top-6 z-10 bg-white py-1 px-2 rounded-lg max-h-[200px] overflow-y-auto w-[300px] text-wrap border ">
            {data.topics.map((item) => (
              <div key={item.topicId} className="flex items-start gap-2 mb-1">
                <input
                  type="checkbox"
                  className="rounded-full h-3 w-3 mt-1 border-[#C1C1C1] "
                  checked={selectedTopics.includes(item.topicId)}
                  onChange={() => handleTopicCheckboxChange(item.topicId)}
                />
                <p className="text-[#C1C1C1]">{item.name}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="w-full mt-10 ">
        <div className="bg-[#fff] py-3 rounded-xl mb-10 px-10 downloadSurveyImg">
          <div className="flex items-start ">
            <div className="w-[35%]" style={{ marginTop: `${maxHeight}px` }}>
              {filteredTopics?.map((item, i) => (
                <div key={i} className="w-full my-2">
                  <div className="flex justify-between h-[55px] items-center cursor-pointer transition-all duration-700 border-2 border-[#eee]">
                    <div
                      onClick={() => toggleAccordion(item.topicId)}
                      className="flex items-center w-full justify-between"
                    >
                      <div className="w-full flex items-center justify-between px-4">
                        <p className="text-xs font-semibold text-[#222]">
                          {item.name}
                        </p>

                        <IoIosArrowDown
                          className={`duration-700 transition-transform ${
                            openAccordions.includes(item.topicId)
                              ? "rotate-180"
                              : ""
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={`ms-6 overflow-hidden transition-max-height relative duration-700 ${
                      openAccordions.includes(item.topicId)
                        ? "max-h-96 border-x-2 border-[#eee]"
                        : "max-h-0"
                    }`}
                  >
                    {item.questions?.map((question, ind) => (
                      <div
                        key={ind}
                        className="flex items-center border-r-0 border-b-4 ps-2 border-[#eee] h-[55px]"
                      >
                        <p className="text-xs font-semibold text-[#222]">
                          {question.questionName}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              <div className="flex justify-between h-[55px] items-center cursor-pointer transition-all duration-700 border-2 border-[#eee] w-full">
                <div className="flex items-center w-full justify-between">
                  <div className="w-full flex items-center justify-between px-4">
                    <p className="text-xs font-semibold text-[#222]">
                      Нийт дундаж
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[65%] overflow-x-scroll relative ">
              <div ref={departmentRef} className="relative">
                {filteredTopics?.length > 0 &&
                  filteredTopics.map((item, i) => (
                    <div key={i} className="text-[10px] flex items-end">
                      {i === 0 &&
                        item.depScores?.map((depItem, j) => (
                          <p
                            key={j}
                            className="w-[45px] text-ellipsis me-3 whitespace-pre-line break-words"
                            title={depItem.departName}
                          >
                            {splitDepartmentName(depItem.departName)}
                          </p>
                        ))}
                    </div>
                  ))}
              </div>
              {console.log(filteredTopics)}
              {filteredTopics?.map((item, i) => {
                return (
                  <div>
                    <div
                      key={item.topicId}
                      className={`cursor-pointer transition-all duration-700 mb-2 `}
                    >
                      <div
                        className={`flex items-center ${i === 0 ? "mt-2" : ""}`}
                      >
                        {item.depScores?.map((score) => {
                          let avg = score.score / score.count;

                          return (
                            <div key={score.departmentId} className="relative ">
                              <div
                                onClick={() => {
                                  setDepId(0);
                                  setTopId(0);
                                  setQuestId();

                                  if (selectedData !== null) {
                                    setSelectedSecondData(null);
                                    setSelectedSecondData(selectedData);
                                    setDepId(score.departmentId);
                                    setTopId(item.topicId);
                                  } else {
                                    setDepId(score.departmentId);
                                    setTopId(item.topicId);
                                  }
                                }}
                                className="min-w-[55px] min-h-[55px] relative flex items-center justify-center border-2"
                                style={{
                                  backgroundColor:
                                    avg === 0
                                      ? "#EAEAEA"
                                      : avg < 2
                                      ? "#FF5F6E"
                                      : avg < 3
                                      ? "#FFBD70"
                                      : avg < 4
                                      ? "#FFE070"
                                      : avg < 4.5
                                      ? "#7EEFB2"
                                      : avg <= 5
                                      ? "#00D97D"
                                      : " bg-[#EAEAEA]",
                                }}
                              >
                                <p className="text-[#222] font-semibold">
                                  {avg.toFixed(1)}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div>
                        <div
                          className={`overflow-y-hidden overflow-x-scroll scrolling transition-max-height relative  duration-700 ${
                            openAccordions.includes(item.topicId)
                              ? "max-h-96  border-[#eee]"
                              : "max-h-0 "
                          }`}
                        >
                          {item.questions?.map((question) => {
                            return (
                              <div key={question.questionId} className="">
                                <div className="flex">
                                  {question.departments.map((dep) => {
                                    let avg = dep.count
                                      ? dep.score / dep.count
                                      : "-";
                                    return (
                                      <div
                                        key={dep.departmentId}
                                        className="flex items-center"
                                      >
                                        <div
                                          onClick={() => {
                                            setDepId(0);
                                            setTopId(0);
                                            setQuestId(0);

                                            if (selectedData !== null) {
                                              setSelectedSecondData(null);
                                              setSelectedSecondData(
                                                selectedData
                                              );
                                              setDepId(dep.departmentId);
                                              setQuestId(question.questionId);
                                            } else {
                                              setDepId(dep.departmentId);
                                              setQuestId(question.questionId);
                                            }
                                          }}
                                          className={`w-[55px] h-[55px] flex items-center justify-center border-x-2 border-b-4 border-[#eee] relative ${
                                            avg === 0.0
                                              ? "bg-[#EAEAEA]"
                                              : avg < 2
                                              ? "bg-[#FF5F6E]"
                                              : avg < 3
                                              ? "bg-[#FFBD70]"
                                              : avg < 4
                                              ? "bg-[#FFE070]"
                                              : avg === 4
                                              ? "bg-[#7EEFB2]"
                                              : avg <= 5
                                              ? "bg-[#00D97D]"
                                              : " bg-[#EAEAEA]"
                                          }`}
                                        >
                                          <p className="text-[#222] font-semibold">
                                            {isNaN(avg) ? "-" : avg.toFixed(1)}
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex justify-between items-start">
            {selectedData !== null && (
              <div className="border-xl rounded-xl px-4 py-2 my-5">
                <div className="w-[258px] h-[300px]">
                  <div className="relative">
                    <img
                      className="absolute"
                      src="/survey/hoverBg.svg"
                      alt="hover"
                    />
                    <button
                      onClick={() => {
                        setSelectedData(null);
                      }}
                      className="absolute top-2 right-2 z-10"
                    >
                      <IoIosClose className="text-lg text-[#636466]" />
                    </button>
                    <div className="relative px-4 py-4">
                      <p className="text-xs text-[#222] opacity-80 font-semibold mb-2">
                        {selectedData.topicName}
                      </p>
                      <div className="flex items-center gap-2 justify-between">
                        <p className="text-[#636466] text-xs">Хэлтэс:</p>
                        <p className="text-[#222] text-xs text-end font-semibold">
                          {selectedData.departmentName}
                        </p>
                      </div>
                      <div className="flex items-center justify-between mb-2 border-b pb-2 border-[#EEEEEE]"></div>
                      <div className="w-[222px] h-[30px] flex items-center bg-[#EAEAEA] rounded-lg mb-2">
                        <div
                          className="bg-[#00D97D] h-[30px]"
                          style={{ width: `${selectedData.percentScore5}%` }}
                        />
                        <div
                          className="bg-[#7EEFB2] h-[30px]"
                          style={{ width: `${selectedData.percentScore4}%` }}
                        />
                        <div
                          className="bg-[#FFE070] h-[30px]"
                          style={{ width: `${selectedData.percentScore3}%` }}
                        />
                        <div
                          className="bg-[#FFBD70] h-[30px]"
                          style={{ width: `${selectedData.percentScore2}%` }}
                        />
                        <div
                          className="bg-[#FF5F6E] h-[30px]"
                          style={{ width: `${selectedData.percentScore1}%` }}
                        />
                      </div>
                      <div>
                        <div className="flex items-center justify-between mb-1">
                          <div className="flex items-center gap-2">
                            <div className="bg-[#00D97D] w-[20px] h-[20px]" />
                            <p className="text-[#636466] text-xs">5:</p>
                          </div>
                          <p className="text-[#222] font-semibold text-xs">
                            {selectedData.percentScore5}%
                          </p>
                        </div>
                        <div className="flex items-center justify-between mb-1">
                          <div className="flex items-center gap-2">
                            <div className="bg-[#7EEFB2] w-[20px] h-[20px]" />
                            <p className="text-[#636466] text-xs">4:</p>
                          </div>
                          <p className="text-[#222] font-semibold text-xs">
                            {selectedData.percentScore4}%
                          </p>
                        </div>
                        <div className="flex items-center justify-between mb-1">
                          <div className="flex items-center gap-2">
                            <div className="bg-[#FFE070] w-[20px] h-[20px]" />
                            <p className="text-[#636466] text-xs">3:</p>
                          </div>
                          <p className="text-[#222] font-semibold text-xs">
                            {selectedData.percentScore3}%
                          </p>
                        </div>
                        <div className="flex items-center justify-between mb-1">
                          <div className="flex items-center gap-2">
                            <div className="bg-[#FFBD70] w-[20px] h-[20px]" />
                            <p className="text-[#636466] text-xs">2:</p>
                          </div>
                          <p className="text-[#222] font-semibold text-xs">
                            {selectedData.percentScore2}%
                          </p>
                        </div>
                        <div className="flex items-center justify-between mb-1">
                          <div className="flex items-center gap-2">
                            <div className="bg-[#FF5F6E] w-[20px] h-[20px]" />
                            <p className="text-[#636466] text-xs">1:</p>
                          </div>
                          <p className="text-[#222] font-semibold text-xs">
                            {selectedData.percentScore1}%
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {selectedSecondData !== null && (
              <div className="border-xl rounded-xl px-4 py-2 my-5">
                <div className="w-[258px] h-[300px]">
                  <div className="relative">
                    <img
                      className="absolute"
                      src="/survey/hoverBg.svg"
                      alt="hover"
                    />
                    <button
                      onClick={() => {
                        setSelectedSecondData(null);
                      }}
                      className="absolute top-2 right-2 z-10"
                    >
                      <IoIosClose className="text-lg text-[#636466]" />
                    </button>
                    <div className="relative px-4 py-4">
                      <p className="text-xs text-[#222] opacity-80 font-semibold mb-2">
                        {selectedSecondData.topicName}
                      </p>
                      <div className="flex items-center gap-2 justify-between">
                        <p className="text-[#636466] text-xs">Хэлтэс:</p>
                        <p className="text-[#222] text-xs text-end font-semibold">
                          {selectedSecondData.departmentName}
                        </p>
                      </div>
                      <div className="flex items-center justify-between mb-2 border-b pb-2 border-[#EEEEEE]"></div>
                      <div className="w-[222px] h-[30px] flex items-center bg-[#EAEAEA] rounded-lg mb-2">
                        <div
                          className="bg-[#00D97D] h-[30px]"
                          style={{
                            width: `${selectedSecondData.percentScore5}%`,
                          }}
                        />
                        <div
                          className="bg-[#7EEFB2] h-[30px]"
                          style={{
                            width: `${selectedSecondData.percentScore4}%`,
                          }}
                        />
                        <div
                          className="bg-[#FFE070] h-[30px]"
                          style={{
                            width: `${selectedSecondData.percentScore3}%`,
                          }}
                        />
                        <div
                          className="bg-[#FFBD70] h-[30px]"
                          style={{
                            width: `${selectedSecondData.percentScore2}%`,
                          }}
                        />
                        <div
                          className="bg-[#FF5F6E] h-[30px]"
                          style={{
                            width: `${selectedSecondData.percentScore1}%`,
                          }}
                        />
                      </div>
                      <div>
                        <div className="flex items-center justify-between mb-1">
                          <div className="flex items-center gap-2">
                            <div className="bg-[#00D97D] w-[20px] h-[20px]" />
                            <p className="text-[#636466] text-xs">5:</p>
                          </div>
                          <p className="text-[#222] font-semibold text-xs">
                            {selectedSecondData.percentScore5}%
                          </p>
                        </div>
                        <div className="flex items-center justify-between mb-1">
                          <div className="flex items-center gap-2">
                            <div className="bg-[#7EEFB2] w-[20px] h-[20px]" />
                            <p className="text-[#636466] text-xs">4:</p>
                          </div>
                          <p className="text-[#222] font-semibold text-xs">
                            {selectedSecondData.percentScore4}%
                          </p>
                        </div>
                        <div className="flex items-center justify-between mb-1">
                          <div className="flex items-center gap-2">
                            <div className="bg-[#FFE070] w-[20px] h-[20px]" />
                            <p className="text-[#636466] text-xs">3:</p>
                          </div>
                          <p className="text-[#222] font-semibold text-xs">
                            {selectedSecondData.percentScore3}%
                          </p>
                        </div>
                        <div className="flex items-center justify-between mb-1">
                          <div className="flex items-center gap-2">
                            <div className="bg-[#FFBD70] w-[20px] h-[20px]" />
                            <p className="text-[#636466] text-xs">2:</p>
                          </div>
                          <p className="text-[#222] font-semibold text-xs">
                            {selectedSecondData.percentScore2}%
                          </p>
                        </div>
                        <div className="flex items-center justify-between mb-1">
                          <div className="flex items-center gap-2">
                            <div className="bg-[#FF5F6E] w-[20px] h-[20px]" />
                            <p className="text-[#636466] text-xs">1:</p>
                          </div>
                          <p className="text-[#222] font-semibold text-xs">
                            {selectedSecondData.percentScore1}%
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="bg-[#fff] py-3 rounded-xl mb-5">
          <div className="flex justify-center my-6">
            <div className="mx-10 w-[450px]">
              <select
                className="w-full text-[#667085] bg-[#F4F8FF] border-[#D0D0D0] rounded-xl"
                onChange={handleSelectChange}
                value={selectedQuestionId}
              >
                {filteredTopics?.map((topic) => (
                  <optgroup key={topic.topicId} label={topic.name}>
                    {topic.questions?.map((question) => (
                      <option
                        key={question.questionId}
                        value={question.questionId}
                      >
                        {question.questionName}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </select>
            </div>
          </div>
          <div className="flex items-center justify-center gap-10 mb-6">
            <div className="w-[200px] h-[200px]">
              <Doughnut
                data={filteredChartData}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
            </div>
            <div>
              {filteredChartData.labels.map((label, index) => (
                <div
                  key={index}
                  className="flex items-center gap-6 mb-3 justify-between"
                >
                  <div className="flex items-center gap-2">
                    <div
                      className="w-2 h-2 rounded-full"
                      style={{
                        backgroundColor:
                          filteredChartData.datasets[0].backgroundColor[index],
                      }}
                    />
                    <p className="text-xs text-[#1c1c1c]">{label}</p>
                  </div>
                  <p className="text-xs text-[#1c1c1c]">
                    {data.surveyFinished === 0
                      ? "0%"
                      : filteredChartData.percentages[index]}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="bg-[#fff] py-3 rounded-xl mb-5 px-10">
          {filteredTopics?.map((items) => {
            return (
              <div key={items.topicID}>
                <p className="text-lg text-[#1E1E1E] font-semibold mb-3">
                  {items.name}
                </p>
                {items.questions?.map((question) => {
                  let totalScore1Count = 0;
                  let totalScore2Count = 0;
                  let totalScore3Count = 0;
                  let totalScore4Count = 0;
                  let totalScore5Count = 0;

                  question.departments.forEach((dept) => {
                    totalScore1Count += dept.score1Count || 0;
                    totalScore2Count += dept.score2Count || 0;
                    totalScore3Count += dept.score3Count || 0;
                    totalScore4Count += dept.score4Count || 0;
                    totalScore5Count += dept.score5Count || 0;
                  });

                  const maxCount = Math.max(
                    totalScore1Count,
                    totalScore2Count,
                    totalScore3Count,
                    totalScore4Count,
                    totalScore5Count
                  );

                  const maxValues = [
                    { value: totalScore1Count, index: 0 },
                    { value: totalScore2Count, index: 1 },
                    { value: totalScore3Count, index: 2 },
                    { value: totalScore4Count, index: 3 },
                    { value: totalScore5Count, index: 4 },
                  ].filter((item) => item.value === maxCount);

                  const backgroundColors = [
                    "#F0F0F0",
                    "#F0F0F0",
                    "#F0F0F0",
                    "#F0F0F0",
                    "#F0F0F0",
                  ];

                  if (maxValues.length === 1) {
                    backgroundColors[maxValues[0].index] = "#324D72";
                  }

                  return (
                    <div
                      key={question.questionId}
                      className="flex justify-center mb-6"
                    >
                      <div>
                        <p className="text-sm text-[#636466]">
                          {question.questionName}
                        </p>
                        <div className="flex justify-center mt-5">
                          <div className="w-[266px]">
                            <Bar
                              data={{
                                labels: [1, 2, 3, 4, 5],
                                datasets: [
                                  {
                                    data: [
                                      totalScore1Count,
                                      totalScore2Count,
                                      totalScore3Count,
                                      totalScore4Count,
                                      totalScore5Count,
                                    ],
                                    backgroundColor: backgroundColors,

                                    borderRadius: 8,
                                  },
                                ],
                              }}
                              options={{
                                plugins: {
                                  legend: { display: false },
                                  datalabels: {
                                    anchor: "end ",
                                    align: "start",
                                    color: function (context) {
                                      const backgroundColor =
                                        context.dataset.backgroundColor[
                                          context.dataIndex
                                        ];
                                      return backgroundColor === "#324D72"
                                        ? "#ffffff"
                                        : "#000000";
                                    },
                                    font: {
                                      size: 16,
                                    },
                                    formatter: (value) => `${value}%`,
                                  },
                                },
                                scales: {
                                  x: {
                                    display: true,
                                    grid: {
                                      display: false,
                                    },
                                    ticks: {
                                      font: {
                                        size: 14,
                                        weight: "bold",
                                      },
                                      color: "#000000",
                                    },
                                  },
                                  y: {
                                    display: false,
                                    grid: {
                                      display: false,
                                    },
                                  },
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SurveySummary;
