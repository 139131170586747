import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getReport } from "../../../services/softSkill.service";

const SoftSkillReport = ({ list }) => {
  const [id, setId] = useState(null);
  const [dep, setDep] = useState([]);
  const navigate = useNavigate();
  const [average, setAverage] = useState({ ci: 0, mpi: 0 }); // Initial state for averages

  useEffect(() => {
    setId(list[0]?.id);
  }, [list]);

  useEffect(() => {
    if (id !== null) {
      getReport(id)
        .then((data) => {
          const totalCi = data.data.reduce(
            (sum, department) => sum + department.averageCi,
            0
          );
          const totalMpi = data.data.reduce(
            (sum, department) => sum + department.averageMpi,
            0
          );
          const numDepartments = data.data.length;

          const averageCi = numDepartments > 0 ? totalCi / numDepartments : 0;
          const averageMpi = numDepartments > 0 ? totalMpi / numDepartments : 0;

          setAverage({ ci: averageCi, mpi: averageMpi });

          const transformedData = data.data.map((department) => {
            console.log(department);
            return {
              depName: department.departmentName || "Unknown",
              depID: department.departmentId,
              scores: [
                {
                  enName: "mpi",
                  mnName: "MPI оноо",
                  score: department.averageMpi,
                },
                {
                  enName: "ci",
                  mnName: "CI оноо",
                  score: department.averageCi,
                },
                {
                  enName: "averageDeductiveReasoning",
                  mnName: "Ерөнхийлөн дүгнэх",
                  score: department.averageDeductiveReasoning,
                },
                {
                  enName: "averageInductiveReasoning",
                  mnName: "Нарийвчлан дүгнэх",
                  score: department.averageInductiveReasoning,
                },
                {
                  enName: "averageQuantitativeReasoning",
                  mnName: "Тоон сэтгэлгээ",
                  score: department.averageQuantitativeReasoning,
                },
                {
                  enName: "averageAttention",
                  mnName: "Анхаарал",
                  score: department.averageAttention,
                },
                {
                  enName: "averageWorkingMemory",
                  mnName: "Ажлын санах ой",
                  score: department.averageWorkingMemory,
                },
                {
                  enName: "averageProcessingSpeed",
                  mnName: "Боловсруулах хурд",
                  score: department.averageProcessingSpeed,
                },
                {
                  enName: "averagePatternRecognition",
                  mnName: "Хэв таних",
                  score: department.averagePatternRecognition,
                },
                {
                  enName: "averageCriticalThinking",
                  mnName: "Нухацтай сэтгэлгээ",
                  score: department.averageCriticalThinking,
                },
                {
                  enName: "averageDecisionMaking",
                  mnName: "Шийдвэр гаргах чадвар",
                  score: department.averageDecisionMaking,
                },
                {
                  enName: "averagePlanning",
                  mnName: "Төлөвлөх чадвар",
                  score: department.averagePlanning,
                },
                {
                  enName: "averageLearningRate",
                  mnName: "Суралцах хурд",
                  score: department.averageLearningRate,
                },
                {
                  enName: "averageCuriosity",
                  mnName: "Сониуч зан",
                  score: department.averageCuriosity,
                },
                {
                  enName: "averageRiskTaking",
                  mnName: "Эрсдэл хүлээх чадвар",
                  score: department.averageRiskTaking,
                },
                {
                  enName: "averageAdaptability",
                  mnName: "Дасан зохицох чадвар",
                  score: department.averageAdaptability,
                },
                {
                  enName: "averageConsistence",
                  mnName: "Тууштай байдал",
                  score: department.averageConsistence,
                },
                {
                  enName: "averageGoalSetting",
                  mnName: "Зорилго төлөвлөлт",
                  score: department.averageGoalSetting,
                },
                {
                  enName: "averageAssertiveness",
                  mnName: "Багаар ажиллах чадвар",
                  score: department.averageAssertiveness,
                },
                {
                  enName: "averageTrusting",
                  mnName: "Бусдад итгэх хандлага",
                  score: department.averageTrusting,
                },
                {
                  enName: "averageAssertiveness",
                  mnName: "Өөртөө итгэлтэй, шийдэмгий байдал",
                  score: department.averageAssertiveness,
                },
              ],
            };
          });

          setDep(transformedData);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const headers = [
    "MPI оноо",
    "CI оноо",
    "Ерөнхийлөн дүгнэх",
    "Нарийвчлан дүгнэх",
    "Тоон сэтгэлгээ",
    "Анхаарал",
    "Ажлын санах ой",
    "Боловсруулах хурд",
    "Хэв таних",
    "Нухацтай сэтгэлгээ",
    "Шийдвэр гаргах чадвар",
    "Төлөвлөх чадвар",
    "Суралцах хурд",
    "Сониуч зан",
    "Эрсдэл хүлээх чадвар",
    "Дасан зохицох чадвар",
    "Тууштай байдал",
    "Зорилго төлөвлөлт",
    "Багаар ажиллах чадвар",
    "Бусдад итгэх хандлага",
    "Өөртөө итгэлтэй, шийдэмгий байдал",
  ];

  if (dep.length === 0) {
    return (
      <div className="flex items-center justify-between">
        <p className="text-xl font-semibold text-[#1E293B]">
          Мэдээлэл байхгүй байна
        </p>
        {list.length > 0 && (
          <select
            onChange={(e) => {
              setId(e.target.value);
            }}
            className="text-sm rounded-lg text-[#1E293B] ps-3 pe-8 py-2"
          >
            {list.map((items, index) => (
              <option key={index} value={items.id}>
                {items.name}
              </option>
            ))}
          </select>
        )}
      </div>
    );
  }
  return (
    <div className="pt-11">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-10">
          <div className="w-[160px] h-[74px] rounded-xl shadow-xl flex items-center px-4 py-3 bg-[#fff]">
            <div>
              <p className="text-sm text-[#666874] mb-2">Дундаж MPI оноо</p>
              <p className="text-[24px] font-semibold text-[#324D72]">
                {average.mpi.toFixed(0) || "0"}
              </p>
            </div>
          </div>
          <div className="w-[160px] h-[74px] rounded-xl shadow-xl flex items-center px-4 py-3 bg-[#fff]">
            <div>
              <p className="text-sm text-[#666874] mb-2">Дундаж CI оноо</p>
              <p className="text-[24px] font-semibold text-[#324D72]">
                {average.ci.toFixed(0) || "0"}
              </p>
            </div>
          </div>
        </div>

        <select
          onChange={(e) => {
            setId(e.target.value);
          }}
          className="text-sm rounded-lg text-[#1E293B] ps-3 pe-8 py-2"
        >
          {list.map((items, index) => (
            <option key={index} value={items.id}>
              {items.name}
            </option>
          ))}
        </select>
      </div>
      <div className="mt-8">
        <p className="text-[#1E293B] font-semibold">
          Хэлтэс хоорондын харьцуулалт
        </p>
        <p className="text-sm text-[#777985]">
          Хэлтэс тус бүр дээр дарж тухайн хэлтсийн ажилчдын үзүүлэлтийг харах
          боломжтой.
        </p>
        <div className="overflow-x-auto my-10">
          <table className="min-w-full bg-white rounded-xl">
            <thead>
              <tr>
                <th className="px-3 py-1 text-left text-sm font-semibold text-[#1E293B] uppercase tracking-wider"></th>
                {headers.map((header, index) => (
                  <th
                    key={index}
                    className={`px-3 py-1 text-xs font-semibold text-[#1E293B] uppercase tracking-wider bg-none text-center`}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="pb-8">
              {dep.map((items, rowIndex) => (
                <tr key={rowIndex}>
                  <td
                    onClick={() => {
                      navigate(`report/${id}/${items.depID}`);
                    }}
                    className="px-6 py-4 whitespace-nowrap hover:bg-[#E9EDF5] text-sm font-medium text-gray-900"
                  >
                    {items.depName}
                  </td>
                  {items.scores?.map((cell, cellIndex) => (
                    <td key={cellIndex} className={`px-1 whitespace-nowrap`}>
                      <div className="py-3">
                        <p className="text-center text-sm text-[#324D72] font-bold">
                          {cell.score?.toFixed(0)}
                        </p>
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SoftSkillReport;
